import React, { Component } from "react";
import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import Table from "./components/Table/Table";
import ProcessReference from "./pages/ProcessReference";

export default class App extends Component {
	// TODO: There should be a better way to handle the table rather than using these "Global"
	/**
	 * Initializes the App component by setting its properties and state.
	 *
	 * Sets the current location, spreadsheet ID, table data, table column names, and initial state.
	 *
	 * @return {undefined}
	 */
	constructor() {
		super();
		this.currentLocation = window.location.pathname;
		this.spreadsheetId = process.env.REACT_APP_SPREADSHEET_DB_ID;
		this.tableData = {};
		this.tableColumnsNames = {
			workRequests: [
				{
					title: "Internal PO Number",
					field: "internalPoNumber",
					editable: "never",
				},
				{ title: "Customer PO Number", field: "customerPoNumber" },
				{ title: "Job Status", field: "jobStatus" },
				{ title: "Type", field: "workOrderType" },
				{ title: "Client Name", field: "clientName" },
				{ title: "Location Name", field: "locationName" },
				{ title: "Billing Address", field: "billingAddress" },
				{ title: "Work Address", field: "workAddress" },
				{ title: "Requested By", field: "requestedBy" },
				{ title: "Contact Number", field: "contactNumber" },
				{
					title: "Invoice Email Address",
					field: "invoiceEmailAddress",
				},
				{ title: "Called In Date", field: "calledInDate" },
			],
			crewMemberManagement: [
				{
				},
				{ title: "Crew Member ", field: "name" },
				{ title: "Crew Member  Email", field: "email" },
			],
		};
		this.state = {
			isLogin: false,
			tableData: null,
			data: null,
			requestData: null,
			invoiceData: null,
			reportData: null,
			loading: true,
		};
	}

	
	componentDidMount() { 
	}

	// ------- GLOBAL HANDLERS AND SETTERS ------- //

	// updates state of application upon logging in
	handleLogin = (responseToken) => {
		this.setState((prev) => ({
			isLoggedIn: true,
		}));
	};

	// updates state of application upon logging out
	handleLogout = () => {
		this.setState((prev) => ({
			isLoggedIn: false,
		}));
	};

	setTableData = (data) => {
		this.setState((prev) => ({
			tableData: data,
		}));
	};

	setStateRequestData = (data) => {
		this.setState((prev) => ({
			requestData: data,
		}));
	};

	setStateInvoiceData = (data) => {
		this.setState((prev) => ({
			invoiceData: data,
		}));
	};

	setStateReportData = (data) => {
		this.setState((prev) => ({
			reportData: data,
		}));
	};

	setData = (data) => {
		this.setState((prev) => ({
			data: data,
		}));
	};

	setTableColumns = (data) => {
		this.setState((prev) => ({
			tableColumns: data,
		}));
	};

	updateCrewMemberManagementDataState = (data) => {
		this.setState((prev) => ({
			...prev,
			data: { ...prev.data, crewMemberData: data },
		}));
	};

	// ------- END GLOBAL HANDLERS AND SETTERS ------- //

	render() {
		return (
			<Router>
				<div className='content'>
					<Header
						handleLogin={this.handleLogin}
						handleLogout={this.handleLogout}
						isLoggedIn={this.state.isLoggedIn}
						setTableData={this.setTableData}
						setStateRequestData={this.setStateRequestData}
						setStateInvoiceData={this.setStateInvoiceData}
						setStateReportData={this.setStateReportData}
						data={this.state.data}
						tableColumnsNames={this.tableColumnsNames}
						setTableColumns={this.setTableColumns}
						setData={this.setData}
						tableData={this.state.tableData}
						requestData={this.state.requestData}
						invoiceData={this.state.invoiceData}
						reportData={this.state.reportData}
						currentLocation={this.currentLocation}
						tableColumnsState={this.state.tableColumns}
						updateCrewMemberManagementDataState={
							this.updateCrewMemberManagementDataState
						}
					/>
					<div className='push'></div>
					{this.state.isLoggedIn ? (
											<Switch>
											<Route path='/work_requests'>
												<Table
													tableTitle='Work Requests'
													tableColumnsState={this.tableColumnsNames.workRequests}
													getSpreadsheetData={this.getSpreadsheetData}
													allowRefresh={true}
													key={this.childKey}
												/>
											</Route>
					
											<Route path='/crew_member_management'>
												<Table
													tableColumnsState={this.tableColumnsNames.crewMemberManagement}
													tableTitle='Crew Member Management'
					
													allowRefresh={false}
													updateCrewMemberManagementDataState={
														this.updateCrewMemberManagementDataState
													}
													getSpreadSheetData={this.getSpreadSheetData}
												/>
											</Route>
											<Route path='/process_reference'>
												<ProcessReference />
											</Route>
										</Switch>
					) : (<></>)}
				</div>
				<div className='footer'>
					<img
						alt='Output Logo'
						src={
							process.env.PUBLIC_URL +
							"/output-colour-light-powered.svg"
						}
					/>
				</div>
			</Router>
		);
	}
}
