/**
 * This file contains the AssignedCrew component, which is a React component that
 * renders the crew members assigned to a work order.
 *
 * The component takes in a single prop, `activeCrew`, which is an array of
 * crew members. If there are no crew members assigned or if `activeCrew` is falsy,
 * a simple container with a message indicating that no crew members are assigned
 * at this time. is rendered.
 *
 * If there are crew members assigned, a div container with an unordered list
 * containing CrewMember components for each crew member is rendered. Each
 * CrewMember component is passed the crew member as a prop.
 *
 * The component is exported as the default export from this file.
 */

import React from 'react';

/**
 * Renders the assigned crew members.
 *
 * @param {Object} props - The properties for the component.
 * @param {Array} props.activeCrew - The array of active crew members.
 * @return {JSX.Element} The rendered component.
 */
export default function AssignedCrew(props) {
  const { activeCrew } = props;

  // If there are no active crew members, or if activeCrew is falsy,
  // return a simple container with a message indicating that no crew
  // members are assigned at this time.
  if ((activeCrew.length === 0) || !activeCrew) {
    return (
      <div className="simpleContainer">
        <p>No crew members assigned at this time.</p>
      </div>
    )
  }

  // If there are active crew members, return a div container with an
  // unordered list containing CrewMember components for each crew
  // member. Each CrewMember component is passed the crew member as a
  // prop.
  return (
    <div className="simpleContainer">
      <ul>
        {
          activeCrew.map((member, index) => {
            return (<CrewMember member={member} key={index} />)
          })
        }
      </ul>
    </div>
  )

}

/**
 * Renders a single crew member in a list item.
 *
 * @param {Object} props - The properties for the component.
 * @param {string|Object} props.member - The crew member to render. Can be a string or an object with name and email properties.
 * @return {JSX.Element} The rendered crew member in a list item.
 */
const CrewMember = (props) => {
  const { member } = props;

  /**
   * Logs the crew member to the console for debugging purposes.
   */
  console.log('Member', member);

  /**
   * Renders the crew member in a list item.
   *
   * If the crew member is a string, it is rendered directly in the list item.
   * If the crew member is an object, it is rendered as "{name} ({email})" in the list item.
   *
   */

  // This is to manage pre-migration setting of crews
  if (typeof member === "string") {
    // If the crew member is a non-empty string, render it in a list item.
    if (member) {
      return (<li>{member}</li>);
    }
    // If the crew member is an empty string, return an empty fragment.
    return (<></>);
  }

  // Post migration setting of crews.
  if (typeof member === "object") {
    // Render the crew member's name and email in a list item.
    return (<li>{member.name} ({member.email})</li>);
  }
}