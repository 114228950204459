// TODO - These functions should be moved in a separate file or this file should be renamed (confusing)

import { db } from "./components/Firestore/Firestore.js";
import { regenPDF } from "./Utilities/pdfGen";

/**
 * Updates a work request in the database(firebase) with the provided data. If the job status is 'Ready to Book',
 * it updates the job status to 'Job Booked'. It also updates the customer's information, location, billing
 * address, work address, contact information, and work description. If the 'hasUpdated' flag is true, it
 * regenerates the PDF for the work request.
 *
 * @param {Object} data - The data object containing the updated work request information.
 * @param {boolean} hasUpdated - A flag indicating whether the work request has been updated.
 * @return {Promise<void>} A Promise that resolves when the work request is successfully updated or rejects
 * with an error.
 */
export async function updateWorkRequest(data, hasUpdated) {
    var activeCrew = data.activeCrew;
    //get the job status of the request and if its Ready to Book then push it to Job Booked. Otherwise just leave the jobs status as it is.
    var jobStatus = "";
    if (data.jobStatus === '(03) Ready to Book') {
        jobStatus = '(04) Job Booked';
    }
    else {
        jobStatus = data.jobStatus;
    }


	//Pushes work request data to database
	try {
		await db
			.collection("workRequest")
			.doc(data.internalPoNumber)
			.update({
				customerPoNumber: data.customerPoNumber,
				jobStatus: jobStatus,
				clientName: data.clientName,
				locationName: data.locationName,
				billingAddress: data.billingAddress,
				workAddress: data.workAddress,
				requestedBy: data.requestedBy,
				contactNumber: data.contactNumber,
				email: data.invoiceEmailAddress,
				reportEmail: data.reportEmailAddress,
				calledInDate: data.calledInDate,
				additionalInformation: data.additionalInformation,
				activeCrew: activeCrew ? activeCrew : "",
				workOrderType: data.workOrderType,
				workDescription: data.workDescription
					? data.workDescription
					: "",
			});

		console.log("Successfully pushed data");
	} catch {
		console.log("Failed to push data");
	}

	try {
		// This line of code is updating the 'description' field in the 'invoices' collection of Firestore.
		// It is updating the document that has the same 'invoiceNumber' as the one provided in the 'data' object.
		// The new value for the 'description' field is taken from the 'invoiceDescription' field of the 'data' object.
		await db.collection("invoices").doc(data.invoiceNumber).update({
			description: data.invoiceDescription,
		});

		console.log("Successfully updated invoice description");
	} catch {
		console.log("Failed to update invoice description");
	}


		// Check if the 'hasUpdated' flag is true. If it is, then call the 'regenPDF' function with the 'data' object as an argument.
	if (hasUpdated) {
		regenPDF(data);
	}
}


// TODO - Rename this function there are no more sheets being used. 
/**
 * Updates the crew management sheet by deleting the old crew data and adding the new crew data.
 *
 * @param {object} data - The new crew data to be added.
 * @param {object} oldData - The old crew data to be deleted.
 * @return {void}
 */
export function updateCrewManagementSheet(data, oldData) {
	//console.log(data);
	try {
		db.collection("Crews").doc(oldData.email).delete();
	} catch {
		console.log("email not changed");
	}

	try {
		db.collection("Crews")
			.doc(data.email)
			.set({
				name: data.name ? data.name : "",
			});
	} catch {
		console.log("error adding in crew");
	}

}


// TODO - Rename this function there are no more sheets being used.
/**
 * Adds a new crew management sheet to the database.
 *
 * @param {object} data - The crew data to be added, including email and name.
 * @return {void}
 */
export function addCrewManagementSheet(data) {
    //console.log(data);
    try {
        db.collection('Crews').doc(data.email).set({
            'name': data.name ? data.name : "",
        });
    }
    catch {
        console.log("error adding in crew");
    }
}


// TODO - Rename this function there are no more sheets being used.
/**
 * Deletes a crew management sheet from the database.
 *
 * @param {Object} data - The crew data to be deleted, including the email.
 * @return {Promise<void>} A promise that resolves when the deletion is complete.
 */
export function clearCrewManagementSheet(data) {
	//console.log(data);
	try {
		db.collection("Crews").doc(data.email).delete();
	} catch {
		console.log("email not found");
	}

}
