import React, { Component } from "react";
import { Link } from "react-router-dom";

import Login from "../Login/Login";
import "./Header.css";

/**
 * Header component that displays the Knights Roofing logo and navigation links.
 *
 * @class Header
 * @extends {Component}
 */
export default class Header extends Component {
	render() {
		++this.childKey;

		return (
			<div className='header'>
				<div className='header_container'>
					<div className='header_logo'>
						<img
							alt='Knights Roofing Logo'
							src={process.env.PUBLIC_URL + "/knights-logo.webp"}
						/>
						<h2>Admin Centre</h2>
					</div>
					{this.props.isLoggedIn ? (
						<div className='header_links'>
							<Link
								to='/work_requests'
							>
								Work Requests
							</Link>

							<Link
								to='/crew_member_management'
							>
								Crew Member Management
							</Link>
							{/* Need to fix this link so it says Knights Roofing */}
							<a
								href='https://output-forms.hireoutput.report/form-entry?formID=15'
								target={"_blank"}
								rel='noreferrer'
							>
								Work Request Form
							</a>
							<Link to='/process_reference'>
								Process Reference
							</Link>
							<Login
								handleLogin={this.props.handleLogin}
								handleLogout={this.props.handleLogout}
								isLoggedIn={this.props.isLoggedIn}
							/>
						</div>
					) : (
						<div className='header_links'>
							<Login
								handleLogin={this.props.handleLogin}
								handleLogout={this.props.handleLogout}
								isLoggedIn={this.props.isLoggedIn}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}
