import React from 'react';
import './ProcessReference.css'

/**
 * This function generates a process reference table for a work order management system.
 * It displays a table with job statuses and their corresponding explanations.
 *
 * @return {JSX.Element} A JSX element representing the process reference table.
 */
export default function ProcessReference() {
  const process = [
    {
      'jobStatus': 'Lead Submitted',
      'description': 'Automatically set when the Work Request Form is submitted. Office Managers will receive email notification of form submission.'
    },
    {
      'jobStatus': 'Quote Requested',
      'description': 'Manually set by the Office Manager(s).'
    },
    {
      'jobStatus': 'Ready to Book',
      'description': 'Manually set by the Office Manager(s) at the same time as the Crew is selected. You will be unable to "Confirm Changes" on this status unless you have the Crew selected.'
    },
    {
      'jobStatus': 'Job Booked',
      'description': 'Automatically set following the completion of the previous status causing the email notification (containing the job details and link to Work Record Form) to be sent to the Crew.'
    },
    {
      'jobStatus': 'Temporary Repair',
      'description': 'Automatically set when the Work Record Form is submitted with the Job Status on the form set to Temporary Repair. Office Manager(s) will receive email notification of form submission.'
    },
    {
      'jobStatus': 'Job Reassigned',
      'description': 'Manually set by the Office Manager(s) and will send an email notification to the selected crew. Crew will receive email notification of assignment of job and link to Work Record Form.'
    },
    {
      'jobStatus': 'Work Completed',
      'description': 'Automatically set when the Work Record Form is submitted with the Job Status on the form set to Work Completed. Office Manager(s) will receive email notification of form submission.'
    },
    {
      'jobStatus': 'Ready to Invoice',
      'description': 'Automatically set when the Job Status changes to Work Completed. Office Manager(s) will receive email notification with link to Invoice Form.'
    },
    {
      'jobStatus': 'Invoice Prepared',
      'description': 'Automatically set when the Invoice Form is submitted. Office Manager(s) will receive email notification of form submission.'
    },
    {
      'jobStatus': 'Invoice Sent',
      'description': 'Manually set by the Office Manager(s) and will trigger the emailing of the Invoice to the client based on the email in the Invoice Email Address field.'
    },
    {
      'jobStatus': 'Pending',
      'description': 'Manually set by the Office Manager(s) and can be triggered at any point in the proccess. Is used as a pause for projects incase anything happens during a Work Request.'
    },
  ]
  return (
    <div className="container">
      <h1>Process Reference</h1>

      <table id="referenceTable">
        <tr>
          <th>Job Status</th>
          <th>Explanation</th>
        </tr>
        {process.map((item) => (
          <tr>
            <td>{item.jobStatus}</td>
            <td><p>{item.description}</p></td>
          </tr>
        ))}
      </table>
    </div>
  )
}