import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import "./Login.css";

// TODO: Rethink this authentication process

// # Google Sheets API Configuration:
const GOOGLE_API_KEY = "AIzaSyCwrvLOczCPqn3A3jqT9gVdW59KQNYtR5g";
const GOOGLE_API_CLIENT_ID =
	"455100717688-s85mrl7rmjbosrc74qmhettublliviv3.apps.googleusercontent.com";
const GOOGLE_API_SCOPES = "https://www.googleapis.com/auth/userinfo.profile";

export default class Login extends Component {
	componentDidMount() {
		//called automatically by React, similar to $(document).ready
		this.handleClientLoad();
	}

	handleClientLoad = () => {
		if (typeof window !== "undefined")
			window.gapi.load("client:auth2", this.initClient);
	};

	initClient = () => {
		//provide the authentication credentials you set up in the Google developer console
		window.gapi.client.init({
			apiKey: GOOGLE_API_KEY,
			clientId: GOOGLE_API_CLIENT_ID,
			fetch_basic_profile: true,
			scope: GOOGLE_API_SCOPES, // defines admin abilities for user thats logged in i.e. read only, read & write etc
		});
		window.gapi.auth2
			.getAuthInstance()
			.isSignedIn.listen(this.updateSignInStatus);
	};

	// clicking login button brings up google OAuth
	login = (event) => {
		window.gapi.auth2
			.getAuthInstance()
			.signIn({
				ux_mode: "popup",
			})
			.then(() => {
				console.log("was logged in");
				this.props.handleLogin();
			})
			.catch((err) => {
				console.log("Error in logging in:", err);
			});
	};

	// clicking logout button signs user out of OAuth
	logout = (event) => {
		window.gapi.auth2
			.getAuthInstance()
			.signOut()
			.then(() => {
				console.log("Log out");
			});
		this.props.handleLogout();
	};

	updateSignInStatus = (isSignedIn) => {
		if (isSignedIn) {
			this.props.handleLogin();
		} else {
			this.props.handleLogout();
		}
	};

	render() {
		return (
			<>
				{this.props.isLoggedIn ? (
					<Button
						onClick={() => this.logout()}
						className='button'
						variant='contained'
						color='primary'
					>
						Logout
					</Button>
				) : (
					<Button
						onClick={() => this.login()}
						className='button'
						variant='contained'
						color='primary'
					>
						Login
					</Button>
				)}
			</>
		);
	}
}
