import "date-fns";
import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import "./Modal.css";
import FormModal from "../Form/Form";

import {
  getInvoiceData,
  getReportData,
  getCrewData,
  getOneWorkRequestData,
} from "../Firestore/Firestore.js";

/**
 * A React functional component that renders a modal dialog for displaying work report and invoice data.
 * It fetches the required data from Firestore and updates the modal state accordingly.
 * The rendering of data and the form is handled by the FormModal component.
 * 
 * @param {object} props - The component props.
 * @param {object} props.requestData - The request data object containing the internal PO number.
 * @param {object} props.internalPoNumber - The internal PO number.
 * @param {function} props.handleClose - The function to handle modal close.
 * @param {function} props.refreshRequest - The function to refresh the request.
 * @return {JSX.Element} The modal dialog JSX element.
 */
export default function FormDialog(props) {
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [crewData, setCrewData] = useState({});
  const [requestData, setRequestData] = useState({});

  // Async function to collect the work report and invoice data
  const getData = async (internalPoNumber) => {
    await getReportData(internalPoNumber).then((result) => {
      setReportData(result);
    });
    await getInvoiceData(internalPoNumber).then((result) => {
      setInvoiceData(result);
    });

    await getCrewData().then((result) => {
      setCrewData(result);
    });

    await getOneWorkRequestData(internalPoNumber).then((result) => {
      setRequestData(result);
    })

    setLoading(false);
  };

  // Update the modal state whenever the internalPoNumber prop has changed from the parent (Table);
  useEffect(() => {
    getData(props.requestData.internalPoNumber);
  }, [props.internalPoNumber]);

  // Done loading when reportData, invoiceData, crewData and workRequest data are ready;
  if (loading) {
    return (
      <div>
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
          <DialogActions className="modalHeader">
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="sk-cube-grid">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
          <DialogActions className="modalHeader">
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
          <DialogContent>
            <FormModal
              handleClose={props.handleClose}
              invoiceData={invoiceData}
              reportData={reportData}
              requestData={requestData}
              crewMemberData={crewData}
              refreshRequest={props.refreshRequest}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
