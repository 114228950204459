/**
 * This file contains the FormModal component.
 * 
 * The FormModal component is a the modal which the Admin will be using to interact and manage with all work requests 
 * 
 */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { DataGrid } from "@material-ui/data-grid";
import MuiAlert from "@material-ui/lab/Alert";
import {
	KeyboardDatePicker,
	KeyboardDateTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";

import "../Form/Form.css";
import AssignedCrew from "./AssignedCrew";

import { regenPDF } from "../../Utilities/pdfGen";
import { updateWorkRequest } from "../../gapiFunctions";

const columns = [
	{ field: "id", headerName: "ID", width: 70 },
	{ field: "email", headerName: "Email", width: 200 },
	{ field: "name", headerName: "Name", width: 200 },
];

// Static array for Work Order Types;
const workOrderTypeOptions = [
	{ value: "2nd Year Inspection" },
	{ value: "Cost Plus" },
	{ value: "Deficiency" },
	{ value: "Quoted" },
	{ value: "Quote Request" },
	{ value: "Warranty" },
];

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}


/**
 * Handles the submission of the project information form, including updating the work request data, sending an invoice preparation form link, sending an invoice, and sending a work report. 

 * @param {function} props.handleClose - The function to handle modal close.
 * @param {function} props.refreshRequest - The function to refresh the request.
 * @param {object} props.requestData - The request data object containing the internal PO number.
 * @param {object} props.reportData - The report data object containing the internal PO number.
 * @param {object} props.invoiceData - The invoice data object containing the internal PO number.
 * @param {object} props.crewData - The crew data object containing the internal PO number.
 * @return {Promise} A promise that resolves when the submission is complete.
 */
export default function FormModal(props) {
	const [showReassignCrew, setShowReassignCrew] = useState(false);
	const [showSetCrew, setShowSetCrew] = useState(false);
	const { handleSubmit, errors, control, setValue, getValues } = useForm();
	const [submitError, setSubmitError] = useState(null);
	const [hasUpdated, setHasUpdated] = useState(false);

	const [snackbarWarningOpen, setSnackbarWarningOpen] = useState(false);
	const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
	const [snackbarError, setSnackbarError] = useState("");

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbarWarningOpen(false);
	};

	// Initialization
	const workRequestData = props.requestData;
	const workReportData = props.reportData;

	// Show the set crew if the job status is originally job Reassigned
	useEffect(() => {
		if (workRequestData.jobStatus === "(06) Job Reassigned") {
			setShowSetCrew(true);
		}
	}, [workRequestData.jobStatus]);

	const formatCrew = (crew) => {
		for (let i = 0; i < crew.length; i++) {
			crew[i]["id"] = i + 1;
		}
		return crew;
	};

	// When clicking which crew to select. It will get the selected crew name and compare it with the list of available crews then return the entire crews details
	const findCrew = (crewId) => {
		var crewDetail = [];
		let j = 0;
		for (let i = 0; i < props.crewMemberData.length; i++) {
			if (parseInt(crewId[j]) === props.crewMemberData[i].id) {
				crewDetail.push(props.crewMemberData[i]);
				j++;
			}
		}
		return crewDetail;
	};

	// TODO: Remove , not used.
	const HTMLparse = (crewInfo) => {
		var newString = [];
		for (let i = 0; i < crewInfo.length; i++) {
			newString.push(
				"&Crew%20Member%20" + (i + 1) + "=" + crewInfo[i].name
			);
		}
		return newString.join("");
	};

	
	/**
	 * Returns an object containing a boolean indicating whether the job status can be changed and an array of objects representing the available job status actions.
	 *
	 * @param {string} currentJobStatus - The current status of the job.
	 * @return {object} An object with 'enabled' and 'options' properties. 'enabled' is a boolean indicating whether the job status can be changed, and 'options' is an array of objects representing the available job status actions.
	 */
	const getJobStatusActions = (currentJobStatus) => {
		let jobStatusActions = [];
		let canChangeJobStatus = true;

		switch (currentJobStatus) {
			case "(01) Lead Submitted":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(01) Lead Submitted",
					name: "Lead Submitted",
				});
				jobStatusActions.push({
					value: "(02) Quote Requested",
					name: "Quote Requested",
				});
				jobStatusActions.push({
					value: "(03) Ready to Book",
					name: "Ready To Book",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(02) Quote Requested":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(01) Lead Submitted",
					name: "Lead Submitted",
				});
				jobStatusActions.push({
					value: "(02) Quote Requested",
					name: "Quote Requested",
				});
				jobStatusActions.push({
					value: "(03) Ready to Book",
					name: "Ready To Book",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(03) Ready to Book":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(03) Ready to Book",
					name: "Ready To Book",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(04) Job Booked":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(01) Lead Submitted",
					name: "Lead Submitted",
				});
				jobStatusActions.push({
					value: "(02) Quote Requested",
					name: "Quote Requested",
				});
				jobStatusActions.push({
					value: "(03) Ready to Book",
					name: "Ready To Book",
				});
				jobStatusActions.push({
					value: "(04) Job Booked",
					name: "Job Booked",
				});
				jobStatusActions.push({
					value: "(06) Job Reassigned",
					name: "Job Reassigned",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(05) Temporary Repair":
				canChangeJobStatus = true;
				// jobStatusActions.push({ value: '(01) Lead Submitted', name: 'Lead Submitted' });
				// jobStatusActions.push({ value: '(02) Quote Requested', name: 'Quote Requested' });
				// jobStatusActions.push({ value: '(03) Ready to Book', name: 'Ready To Book' });
				jobStatusActions.push({
					value: "(05) Temporary Repair",
					name: "Temporary Repair",
				});
				jobStatusActions.push({
					value: "(05) Work In Progress",
					name: "Work In Progress",
				});
				jobStatusActions.push({
					value: "(06) Job Reassigned",
					name: "Job Reassigned",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(05) Work In Progress":
				canChangeJobStatus = true;
				// jobStatusActions.push({ value: '(01) Lead Submitted', name: 'Lead Submitted' });
				// jobStatusActions.push({ value: '(02) Quote Requested', name: 'Quote Requested' });
				// jobStatusActions.push({ value: '(03) Ready to Book', name: 'Ready To Book' });
				jobStatusActions.push({
					value: "(05) Temporary Repair",
					name: "Temporary Repair",
				});
				jobStatusActions.push({
					value: "(05) Work In Progress",
					name: "Work In Progress",
				});
				jobStatusActions.push({
					value: "(06) Job Reassigned",
					name: "Job Reassigned",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(06) Job Reassigned":
				canChangeJobStatus = true;
				// jobStatusActions.push({ value: '(01) Lead Submitted', name: 'Lead Submitted' });
				// jobStatusActions.push({ value: '(02) Quote Requested', name: 'Quote Requested' });
				// jobStatusActions.push({ value: '(03) Ready to Book', name: 'Ready To Book' });
				jobStatusActions.push({
					value: "(06) Job Reassigned",
					name: "Job Reassigned",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(07) Work Completed":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(07) Work Completed",
					name: "Work Completed",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(08) Ready to Invoice":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(09) Invoice Prepared":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(09) Invoice Prepared",
					name: "Invoice Prepared",
				});
				//jobStatusActions.push({ value: '(10) Invoice Sent', name: 'Invoice Sent' });
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(10) Invoice Sent":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(10) Invoice Sent",
					name: "Invoice Sent",
				});
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				break;
			case "(11) Pending":
				canChangeJobStatus = true;
				jobStatusActions.push({
					value: "(11) Pending",
					name: "Pending",
				});
				jobStatusActions.push({
					value: "(01) Lead Submitted",
					name: "Lead Submitted",
				});
				jobStatusActions.push({
					value: "(02) Quote Requested",
					name: "Quote Requested",
				});
				jobStatusActions.push({
					value: "(03) Ready to Book",
					name: "Ready To Book",
				});
				jobStatusActions.push({
					value: "(04) Job Booked",
					name: "Job Booked",
				});
				jobStatusActions.push({
					value: "(05) Temporary Repair",
					name: "Temporary Repair",
				});
				jobStatusActions.push({
					value: "(06) Job Reassigned",
					name: "Job Reassigned",
				});
				jobStatusActions.push({
					value: "(07) Work Completed",
					name: "Work Completed",
				});
				jobStatusActions.push({
					value: "(08) Ready to Invoice",
					name: "Ready to Invoice",
				});
				jobStatusActions.push({
					value: "(09) Invoice Prepared",
					name: "Invoice Prepared",
				});
				jobStatusActions.push({
					value: "(10) Invoice Sent",
					name: "Invoice Sent",
				});
				break;
			default:
				break;
		}

		return {
			enabled: canChangeJobStatus,
			options: jobStatusActions,
		};
	};

	// Set default empty objects for the Report and Invoice data
	// TODO: To remove this once we have completion date in work request collection.
	let lastReportData = {
		completionDate: "",
	};

	// TODO: Can potentially remove this initializer....
	let invoiceData = {
		invoiceNumber: "",
		invoiceDate: "",
		pricing: "",
		subTotal: "",
		gst: "",
		total: "",
	};

	// If there are reports, then replace the default
	// TODO: This can be removed in the future. Need to refactor database so that "Completion date" is based stored in the "work request" collection.
	if (props.reportData && props.reportData.length > 0) {
		const numReports = props.reportData.length;
		lastReportData = props.reportData[numReports - 1];
	}

	// Catch if there is no invoice yet for the project. Then send an empty array.
	if (props.invoiceData) {
		invoiceData = props.invoiceData;
	}

	// Logic to determine the potential available job status actions
	const currentJobStatus = workRequestData.jobStatus;

	let jobStatusActions = getJobStatusActions(currentJobStatus);

	// Determine if work reporting section should be shown
	const showWorkReportingArr = [
		"(05) Temporary Repair",
		"(05) Work In Progress",
		"(06) Job Reassigned",
		"(07) Work Completed",
		"(08) Ready to Invoice",
		"(09) Invoice Prepared",
		"(10) Invoice Sent",
	];

	const showWorkReporting =
		showWorkReportingArr.filter((status) => {
			return status === currentJobStatus;
		}).length > 0
			? true
			: false;

	// Determine if the invoicing section should be shown.
	const showInvoicingArr = ["(09) Invoice Prepared", "(10) Invoice Sent"];
	const showInvoicing =
		showInvoicingArr.filter((status) => {
			return status === currentJobStatus;
		}).length > 0
			? true
			: false;

	// Small utility function to determine if the project is past completion
	// only (07,08,09,10) are considered complete 
	const isWorkComplete = (jobStatus) => {
		const completeStatusArr = [
			"(07) Work Completed",
			"(08) Ready to Invoice",
			"(09) Invoice Prepared",
			"(10) Invoice Sent",
		];

		const isComplete =
			completeStatusArr.filter((item) => {
				return item === values.jobStatus;
			}).length > 0;

		return isComplete;
	};

	let crew1default = "";
	let crew2default = "";
	let crew3default = "";
	let crew4default = "";

	// variable for storing all Crews
	const crewData = formatCrew(props.crewMemberData);

	// TODO: use this activeCrewArray to populate the default values for crew1, crew2, crew3, crew4.

	const values = {
		internalPoNumber: workRequestData.internalPoNumber,
		customerPoNumber: workRequestData.customerPoNumber,
		jobStatus: workRequestData.jobStatus,
		clientName: workRequestData.clientName,
		locationName: workRequestData.locationName,
		billingAddress: workRequestData.billingAddress,
		workAddress: workRequestData.workAddress,
		requestedBy: workRequestData.requestedBy,
		contactNumber: workRequestData.contactNumber,
		invoiceEmailAddress: workRequestData.invoiceEmailAddress,
		reportEmailAddress: workRequestData.reportEmailAddress,
		calledInDate: workRequestData.calledInDate,
		workOrderType: workRequestData.workOrderType,
		dateOnSite: workRequestData.dateOnSite,
		completionDate: lastReportData.completionDate,
		additionalInformation: workRequestData.additionalInformation,
		workDescription: workRequestData.workDescription,
		crew: workRequestData.crew,
		crewStartTime: workRequestData.crewStartTime,
		crewEndTime: workRequestData.crewEndTime,
		crewHoursOnSite: workRequestData.crewHoursOnSite,
		crew1: workRequestData.crew1 ? workRequestData.crew1 : crew1default,
		crew2: workRequestData.crew2 ? workRequestData.crew2 : crew2default,
		crew3: workRequestData.crew3 ? workRequestData.crew3 : crew3default,
		crew4: workRequestData.crew4 ? workRequestData.crew4 : crew4default,
		crewDetail: "",
		newCrew4: "", //"",
		setCrew: "Select Crew",
		invoiceNumber: invoiceData.invoiceNumber,
		invoiceDate: invoiceData.invoiceDate,
		pricing: invoiceData.pricing,
		subTotal: invoiceData.subTotal,
		gst: invoiceData.gst,
		total: invoiceData.total,
		invoiceDescription: invoiceData.description,
		reportDriveId: workRequestData.PDFS
			? workRequestData.PDFS.reportDriveId
			: "",
		invoiceDriveId: workRequestData.PDFS
			? workRequestData.PDFS.invoiceDriveId
			: "",
		invoiceLastGenerated: workRequestData.PDFS
			? workRequestData.PDFS.invoiceLastGenerated
			: "",
		reportLastGenerated: workRequestData.PDFS
			? workRequestData.PDFS.reportLastGenerated
			: "",
	};


	/**
	 * Define the onSubmit function when the form is submitted.
	 *
	 * @param {Object} values - The values submitted in the form.
	 * @return {Promise<void>} Promise that resolves when the function completes.
	 */
	const onSubmit = async (values) => {
		const { jobStatus } = values;
		values["workDescription"] = workRequestData.workDescription;

		// for ready to book and job reassigned, there is an email that is sent out to the crew members
		if (
			jobStatus === "(03) Ready to Book" ||
			jobStatus === "(06) Job Reassigned"
		) {
			// // TODO: Collect emails of the active crew.
			// // TODO: Need to fix it. it errors if values.setCrew not assigned a value.
			var crewInfo = findCrew(values.setCrew);
			var crewHTML = HTMLparse(crewInfo);
			values["activeCrew"] = crewInfo;
			values["crewHTML"] = crewHTML;
			try {
				await fetch(
					`${process.env.REACT_APP_RPA_URL}/${process.env.REACT_APP_RPA_SCENARIO_ROUTE_1}`,
					{
						method: "POST",
						headers: {
							"content-type": "application/json",
						},
						body: JSON.stringify({
							values,
						}),
					}
				);

				//Now that the job is booked, set the job status to job booked.
				setValue("jobStatus", "(04) Job Booked");
			} catch (err) {
				console.log(err);
				setSubmitError("There was an error with booking the project");

				// Early return, don't close window
				return;
			}
		}

		// updates the work request within firebase and regenerates the pdfs 
		// TODO: review , necessary?
		updateWorkRequest(values, hasUpdated);

		// Close the modal and refresh the data from firestore
		props.handleClose();
	};

	/**
	 * Sends an invoice to the specified URL and optionally sends a final report to another URL.
	 * Note: the invoice/work report has to be generated first.
	 *
	 * @param {Object} values - An object containing the values to be sent in the request.
	 * @param {string} values.workDescription - The description of the work.
	 * @param {string} values.reportEmailAddress - The email address to send the final report to.
	 * @return {Promise<void>} A promise that resolves when the request is complete.
	 */
	const onSendInvoice = async (values) => {
		values["workDescription"] = workRequestData.workDescription;
		try {
			await fetch(
				`${process.env.REACT_APP_RPA_URL}/${process.env.REACT_APP_RPA_SCENARIO_ROUTE_2}`,
				{
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify({
						values,
						type:
							process.env.NEXT_PUBLIC_VERCEL_ENV ??
							process.env.NODE_ENV,
						invoiceFileId: workRequestData.PDFS.invoiceDriveId,
					}),
				}
			);
		} catch (err) {
			console.log(err);
			setSubmitError("There was an error with booking the project");

			// Early return, don't close window
			return;
		}

		if (values.reportEmailAddress !== "") {
			try {
				await fetch(
					`${process.env.REACT_APP_RPA_URL}/${process.env.REACT_APP_RPA_SCENARIO_ROUTE_4}`,
					{
						method: "POST",
						headers: {
							"content-type": "application/json",
						},
						body: JSON.stringify({
							values,
							type:
								process.env.NEXT_PUBLIC_VERCEL_ENV ??
								process.env.NODE_ENV,
							finalReportFileId:
								workRequestData.PDFS.reportDriveId,
						}),
					}
				);
			} catch (err) {
				console.log(err);
				setSubmitError("There was an error with booking the project");

				// Early return, don't close window
				return;
			}
		}
		// TODO: review , necessary?
		updateWorkRequest(values, hasUpdated);
		props.handleClose();
	};

/**
 * Creates body to send to an RPA emailer. This will be used for a link to invoicing form.
 * The invoicing form is used to fill out data so that the invoice pdf can be generated. 
 * @return {Promise<void>} A promise that resolves when the invoice link is sent.
 */
	const onSendInvoiceLink = async () => {
		// Note that the data has to be collected by the input fields in case they haven't submitted their changes yet

		const payload = {
			requestData: {
				workOrderType: getValues("workOrderType"),
				workDescription: getValues("invoiceDescription"),
				jobStatus: getValues("jobStatus"),
				email: getValues("invoiceEmailAddress"),
				requestedBy: getValues("requestedBy"),
				customerPoNumber: getValues("customerPoNumber"),
				clientName: getValues("clientName"),
				workAddress: getValues("billingAddress"),
				locationName: getValues("locationName"),
				contactNumber: getValues("contactNumber"),
				billingAddress: getValues("billingAddress"),
				calledInDate: getValues("calledInDate"),
				additionalInformation: getValues("additionalInformation"),
				internalPoNumber: getValues("internalPoNumber"),
				invoiceNumber: getValues("invoiceNumber")
					? getValues("invoiceNumber")
					: "new",
			},
		};

		console.log("payload", payload);
		await fetch(
			`${process.env.REACT_APP_RPA_URL}/${process.env.REACT_APP_RPA_SCENARIO_ROUTE_3}`,
			{
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({
					// TODO remove
					action: "production",
					requestData: payload.requestData,
				}),
			}
		);
		props.handleClose();
	};

	/**
	 * Regenerates a PDF based on the current input field values.
	 *
	 * This function collects data from the input fields, creates a payload, and then uses the payload to regenerate the PDF.
	 * It also refreshes the request and updates the snackbar warning state.
	 *
	 * @return {Promise<void>} A promise that resolves when the PDF regeneration is complete.
	 */
	const regeneratePDF = async () => {
		// Note that the data has to be collected by the input fields in case they haven't submitted their changes yet
		return new Promise(async (resolve, reject) => {
			const payload = {
				workOrderType: getValues("workOrderType"),
				workDescription: getValues("additionalInformation"),
				jobStatus: getValues("jobStatus"),
				email: getValues("invoiceEmailAddress"),
				requestedBy: getValues("requestedBy"),
				customerPoNumber: getValues("customerPoNumber"),
				clientName: getValues("clientName"),
				workAddress: getValues("billingAddress"),
				locationName: getValues("locationName"),
				contactNumber: getValues("contactNumber"),
				billingAddress: getValues("billingAddress"),
				calledInDate: getValues("calledInDate"),
				additionalInformation: getValues("additionalInformation"),
				internalPoNumber: getValues("internalPoNumber"),
				invoiceNumber: getValues("invoiceNumber"),
			};
			setSnackbarWarningOpen(true);
			// this will force a refresh -- assuming that the pdfs were generated
			// the "dates and fileIds" will reflect that something was generated.
			await regenPDF(payload); // combines "new data" and "old data" (on fb) and then sends payload to pdfmonkey
			await props.refreshRequest(); // refreshRequest is a function from the root table. Calling this will refresh the whole table
			setSnackbarWarningOpen(false);
		});
	};

	return (
		<div>
			<form>
				{" "}
				<div className='ProjectHeader'>
					<h2>Project Information</h2>
					<div>
						<div className='action-container'>
							<Button
								variant='contained'
								color='primary'
								onClick={onSendInvoiceLink}
							>
								Send Invoice Preparation Form Link
							</Button>

							<Button
								variant='contained'
								color='primary'
								onClick={regeneratePDF}
							>
								Update PDF's
							</Button>
						</div>
					</div>
				</div>
				<div className='pdfInfoHeader'>
					<div>
						Invoice Drive ID: {values.invoiceDriveId} <br />
						Report Drive ID: {values.reportDriveId}
						<br />
						Report PDF Last Generated: {values.reportLastGenerated}
						<br />
						Invoice PDF Last Generated:{" "}
						{values.invoiceLastGenerated}
					</div>
				</div>
				<div className='multiple'>
					{/* Show current job status and allow for action to change the job */}
					{jobStatusActions.enabled ? (
						<FormControl
							variant='outlined'
							style={{ width: "100%" }}
						>
							<InputLabel id='select-jobStatus-label'>
								{" "}
								Job Status
							</InputLabel>
							<Controller
								name='jobStatus'
								control={control}
								defaultValue={values.jobStatus}
								render={(props) => (
									<Select
										labelId='select-jobStatus-label'
										id='select-jobStatus'
										// name="jobStatus"
										// defaultValue={values.jobStatus}
										value={props.value}
										onChange={(e) => {
											console.log("job status changed");
											setHasUpdated(true);
											//setFieldValue('jobStatus', val.target.value);
											setValue(
												"jobStatus",
												e.target.value
											);

											if (
												e.target.value ===
												"(03) Ready to Book"
											) {
												setShowSetCrew(true);
											} else {
												setShowSetCrew(false);
											}
											if (
												e.target.value ===
												"(06) Job Reassigned"
											) {
												setShowReassignCrew(true);
											} else {
												setShowReassignCrew(false);
											}
										}}
										// onBlur={handleBlur}
										label='Job Status'
									>
										{jobStatusActions.options.map(
											(actions, index) => (
												<MenuItem
													value={actions.value}
													key={actions.value}
												>
													{actions.name}
												</MenuItem>
											)
										)}
									</Select>
								)}
							/>
						</FormControl>
					) : (
						<FormControl
							variant='outlined'
							style={{ width: "100%" }}
							disabled
						>
							<InputLabel id='select-jobStatus-label'>
								{" "}
								Job Status
							</InputLabel>
							<Controller
								name='jobStatus'
								control={control}
								defaultValue={values.jobStatus}
								render={(props) => (
									<Select
										labelId='select-jobStatus-label'
										id='select-jobStatus'
										value={props.value}
										// onBlur={handleBlur}
										label='Job Status'
									>
										{jobStatusActions.options.map(
											(actions, index) => (
												<MenuItem
													value={actions.value}
													key={actions.value}
												>
													{actions.name}
												</MenuItem>
											)
										)}
									</Select>
								)}
							/>
						</FormControl>
					)}
				</div>
				{
					/* only viewable if NOT in (06) reassigned  */
					// The className is using a ternary operator to conditionally apply a class to the div element. 
					// If the showReassignCrew state is false, the className is set to "visible". 
					// Otherwise, if the showReassignCrew state is true, the className is set to "hide". 
				}

				<div className={!showReassignCrew ? "visible" : "hide"}>
					<div className='multiple'>
						<Controller
							name='internalPoNumber'
							control={control}
							defaultValue={values.internalPoNumber}
							as={
								<TextField
									disabled
									label='Internal PO Number'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
								/>
							}
						/>
						<Controller
							name='customerPoNumber'
							control={control}
							defaultValue={values.customerPoNumber}
							render={(props) => (
								<TextField
									label='Customer PO Number'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
					</div>
					<div className='multiple'>
						<Controller
							name='clientName'
							control={control}
							defaultValue={values.clientName}
							render={(props) => (
								<TextField
									label='Client Name'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
						<Controller
							name='locationName'
							control={control}
							defaultValue={values.locationName}
							render={(props) => (
								<TextField
									label='Location Name'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
					</div>
					<div>
						<Controller
							name='billingAddress'
							control={control}
							defaultValue={values.billingAddress}
							render={(props) => (
								<TextField
									label='Billing Address'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
					</div>
					<div>
						<Controller
							name='workAddress'
							control={control}
							defaultValue={values.workAddress}
							render={(props) => (
								<TextField
									label='Work Address'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
					</div>
					<div className='multiple'>
						<Controller
							name='requestedBy'
							control={control}
							defaultValue={values.requestedBy}
							render={(props) => (
								<TextField
									label='Requested By'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
						<Controller
							name='contactNumber'
							control={control}
							defaultValue={values.contactNumber}
							render={(props) => (
								<TextField
									label='Contact Number'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
						<Controller
							name='invoiceEmailAddress'
							control={control}
							defaultValue={values.invoiceEmailAddress}
							render={(props) => (
								<TextField
									label='Invoice Email Address'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
					</div>
					<div className='multiple'>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Controller
								name='calledInDate'
								control={control}
								defaultValue={values.calledInDate}
								render={(props) => (
									<KeyboardDatePicker
										label='Called In Date'
										style={{ width: "100%" }}
										disableToolbar
										variant='inline'
										inputVariant='outlined'
										format='MM/dd/yyyy'
										margin='normal'
										id='date-picker-inline'
										placeholder='Called In Date'
										value={props.value}
										onChange={(val) => {
											// props.onChange(val);
											setValue(
												"calledInDate",
												dayjs(val).format("MM/DD/YYYY")
											);
											setHasUpdated(true);
										}}
										KeyboardButtonProps={{
											"aria-label": "change date",
										}}
									/>
								)}
							/>
						</MuiPickersUtilsProvider>

						{/* completion date is only shown if work is complete one of these statuses: (07,08,09,10) */}
						{isWorkComplete(values.jobStatus) ? (
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDateTimePicker
									disabled
									label='Completion Date'
									style={{ width: "100%" }}
									inputVariant='outlined'
									format='MM/dd/yyyy hh:mm a'
									placeholder='Completion Date'
									id='date-picker-inline'
									margin='normal'
									value={values.completionDate ?? undefined}
								></KeyboardDateTimePicker>
							</MuiPickersUtilsProvider>
						) : (
							""
						)}
						<Controller
							name='reportEmailAddress'
							control={control}
							defaultValue={values.reportEmailAddress}
							render={(props) => (
								<TextField
									label='Report Email Address'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
					</div>
					<div>
						<FormControl
							variant='outlined'
							style={{ width: "50%" }}
						>
							<InputLabel id='select-jobStatus-label'>
								Work Order Type
							</InputLabel>
							<Controller
								name='workOrderType'
								control={control}
								defaultValue={values.workOrderType}
								render={(props) => (
									<Select
										labelId='select-workOrderType-label'
										id='select-workOrderType'
										value={values.workOrderType}
										onChange={(e) => {
											setValue(
												"workOrderType",
												e.target.value
											);
											setHasUpdated(true);
										}}
										label='Work Order Type'
									>
										{workOrderTypeOptions.map(
											(options, index) => (
												<MenuItem
													value={options.value}
													key={options.value}
												>
													{options.value}
												</MenuItem>
											)
										)}
									</Select>
								)}
							/>
						</FormControl>
					</div>
					<div>
						<h4>Information</h4>
						<Controller
							name='additionalInformation'
							control={control}
							defaultValue={values.additionalInformation}
							render={(props) => (
								<TextField
									multiline
									label='Additional Information'
									variant='outlined'
									type='text'
									style={{ width: "100%" }}
									defaultValue={props.value}
									onChange={(e) => {
										setHasUpdated(true);
										props.onChange(e.target.value);
									}}
								/>
							)}
						/>
					</div>
					<div>
						<h2>Assigned Crew Members</h2>
						<AssignedCrew
							activeCrew={props.requestData.activeCrew}
						/>
					</div>
					{/* The work reporting section is only show for (05, 06, 07, 08, 09, 10) */}
					<div className={showWorkReporting ? "visible" : "hide"}>
						<h2>Work Reporting</h2>
						{/* will also only show if there was a work report that was submitted */}
						{workReportData.length > 0 ? (
							<div>
								{workReportData.map((workReport) => {
									const crewStartTime =
										workReport.crewStartTime;
									const crewEndTime = workReport.crewEndTime;
									const crewHoursOnSite =
										workReport.crewHoursOnSite;

									// Auto set the data for the crew member (if blank) to equal the crew value
									// Ignore this conditional if there is no crew member
									const crew1StartTime =
										workReport.crew1 &&
										!workReport.crew1StartTime
											? crewStartTime
											: workReport.crew1StartTime;
									const crew2StartTime =
										workReport.crew2 &&
										!workReport.crew2StartTime
											? crewStartTime
											: workReport.crew2StartTime;
									const crew3StartTime =
										workReport.crew3 &&
										!workReport.crew3StartTime
											? crewStartTime
											: workReport.crew3StartTime;
									const crew4StartTime =
										workReport.crew4 &&
										!workReport.crew4StartTime
											? crewStartTime
											: workReport.crew4StartTime;
									const crew1EndTime =
										workReport.crew1 &&
										!workReport.crew1EndTime
											? crewEndTime
											: workReport.crew1EndTime;
									const crew2EndTime =
										workReport.crew2 &&
										!workReport.crew2EndTime
											? crewEndTime
											: workReport.crew2EndTime;
									const crew3EndTime =
										workReport.crew3 &&
										!workReport.crew3EndTime
											? crewEndTime
											: workReport.crew3EndTime;
									const crew4EndTime =
										workReport.crew4 &&
										!workReport.crew4EndTime
											? crewEndTime
											: workReport.crew4EndTime;
									const crew1HoursOnSite =
										workReport.crew1 &&
										!workReport.crew1HoursOnSite
											? crewHoursOnSite
											: workReport.crew1HoursOnSite;
									const crew2HoursOnSite =
										workReport.crew2 &&
										!workReport.crew2HoursOnSite
											? crewHoursOnSite
											: workReport.crew2HoursOnSite;
									const crew3HoursOnSite =
										workReport.crew3 &&
										!workReport.crew3HoursOnSite
											? crewHoursOnSite
											: workReport.crew3HoursOnSite;
									const crew4HoursOnSite =
										workReport.crew4 &&
										!workReport.crew4HoursOnSite
											? crewHoursOnSite
											: workReport.crew4HoursOnSite;
									var totalCrewHours = 0;
									for (let i = 1; i < 5; i++) {
										if (workReport["crew" + i] !== "") {
											var hours =
												workReport[
													"crew" + i + "HoursOnSite"
												] !== ""
													? workReport[
															"crew" +
																i +
																"HoursOnSite"
													  ]
													: crewHoursOnSite;
											totalCrewHours += hours * 1;
										}
									}

									//const totalCrewHours = (crew1HoursOnSite * 1 + crew2HoursOnSite * 1 + crew3HoursOnSite * 1 + crew4HoursOnSite * 1).toFixed(2);

									return (
										<div className='workReportContainer'>
											<h4>
												Date on site:{" "}
												{workReport.dateOnSite}
											</h4>
											<h4>Work Description:</h4>
											<p>{workReport.workDescription}</p>

											<h4>Crew Hours</h4>
											<div className='rpt-crew-table'>
												<div className='rpt-crew-table-header'>
													<div></div>
													<div>Start Time</div>
													<div>End Time</div>
													<div>Hours On Site</div>
												</div>
												<div className='rpt-crew-table-row-crew'>
													<div>
														<strong>
															Crew Members
														</strong>
													</div>
													<div>
														<strong>
															{
																workReport.crewStartTime
															}
														</strong>
													</div>
													<div>
														<strong>
															{
																workReport.crewEndTime
															}
														</strong>
													</div>
													<div>
														<strong>
															{totalCrewHours}
														</strong>
													</div>
												</div>
												{workReport.crew1 && (
													<div className='rpt-crew-table-row'>
														<div>
															{workReport.crew1}
														</div>
														<div className='self-center'>
															{crew1StartTime}
														</div>
														<div className='self-center'>
															{crew1EndTime}
														</div>
														<div className='self-center'>
															{crew1HoursOnSite}
														</div>
													</div>
												)}
												{workReport.crew2 && (
													<div className='rpt-crew-table-row'>
														<div>
															{workReport.crew2}
														</div>
														<div className='self-center'>
															{crew2StartTime}
														</div>
														<div className='self-center'>
															{crew2EndTime}
														</div>
														<div className='self-center'>
															{crew2HoursOnSite}
														</div>
													</div>
												)}
												{workReport.crew3 && (
													<div className='rpt-crew-table-row'>
														<div>
															{workReport.crew3}
														</div>
														<div className='self-center'>
															{crew3StartTime}
														</div>
														<div className='self-center'>
															{crew3EndTime}
														</div>
														<div className='self-center'>
															{crew3HoursOnSite}
														</div>
													</div>
												)}
												{workReport.crew4 && (
													<div className='rpt-crew-table-row'>
														<div>
															{workReport.crew4}
														</div>
														<div className='self-center'>
															{crew4StartTime}
														</div>
														<div className='self-center'>
															{crew4EndTime}
														</div>
														<div className='self-center'>
															{crew4HoursOnSite}
														</div>
													</div>
												)}
											</div>

											<h4>Photos</h4>
											<div className='imageGallery'>
												{workReport.photo1 ? (
													<PhotoThumbnail
														url={workReport.photo1}
														caption={
															workReport.caption1
														}
													/>
												) : (
													""
												)}
												{workReport.photo2 ? (
													<PhotoThumbnail
														url={workReport.photo2}
														caption={
															workReport.caption2
														}
													/>
												) : (
													""
												)}
												{workReport.photo3 ? (
													<PhotoThumbnail
														url={workReport.photo3}
														caption={
															workReport.caption3
														}
													/>
												) : (
													""
												)}
												{workReport.photo4 ? (
													<PhotoThumbnail
														url={workReport.photo4}
														caption={
															workReport.caption4
														}
													/>
												) : (
													""
												)}
												{workReport.photo5 ? (
													<PhotoThumbnail
														url={workReport.photo5}
														caption={
															workReport.caption5
														}
													/>
												) : (
													""
												)}
												{workReport.photo6 ? (
													<PhotoThumbnail
														url={workReport.photo6}
														caption={
															workReport.caption6
														}
													/>
												) : (
													""
												)}
												{workReport.photo7 ? (
													<PhotoThumbnail
														url={workReport.photo7}
														caption={
															workReport.caption7
														}
													/>
												) : (
													""
												)}
												{workReport.photo8 ? (
													<PhotoThumbnail
														url={workReport.photo8}
														caption={
															workReport.caption8
														}
													/>
												) : (
													""
												)}
												{workReport.photo9 ? (
													<PhotoThumbnail
														url={workReport.photo9}
														caption={
															workReport.caption9
														}
													/>
												) : (
													""
												)}
												{workReport.photo10 ? (
													<PhotoThumbnail
														url={workReport.photo10}
														caption={
															workReport.caption10
														}
													/>
												) : (
													""
												)}
												{workReport.photo11 ? (
													<PhotoThumbnail
														url={workReport.photo11}
														caption={
															workReport.caption11
														}
													/>
												) : (
													""
												)}
												{workReport.photo12 ? (
													<PhotoThumbnail
														url={workReport.photo12}
														caption={
															workReport.caption12
														}
													/>
												) : (
													""
												)}
												{workReport.photo13 ? (
													<PhotoThumbnail
														url={workReport.photo13}
														caption={
															workReport.caption13
														}
													/>
												) : (
													""
												)}
												{workReport.photo14 ? (
													<PhotoThumbnail
														url={workReport.photo14}
														caption={
															workReport.caption14
														}
													/>
												) : (
													""
												)}
												{workReport.photo15 ? (
													<PhotoThumbnail
														url={workReport.photo15}
														caption={
															workReport.caption15
														}
													/>
												) : (
													""
												)}
												{workReport.photo16 ? (
													<PhotoThumbnail
														url={workReport.photo16}
														caption={
															workReport.caption16
														}
													/>
												) : (
													""
												)}
												{workReport.photo17 ? (
													<PhotoThumbnail
														url={workReport.photo17}
														caption={
															workReport.caption17
														}
													/>
												) : (
													""
												)}
												{workReport.photo18 ? (
													<PhotoThumbnail
														url={workReport.photo18}
														caption={
															workReport.caption18
														}
													/>
												) : (
													""
												)}
												{workReport.photo19 ? (
													<PhotoThumbnail
														url={workReport.photo19}
														caption={
															workReport.caption19
														}
													/>
												) : (
													""
												)}
												{workReport.photo20 ? (
													<PhotoThumbnail
														url={workReport.photo20}
														caption={
															workReport.caption20
														}
													/>
												) : (
													""
												)}
											</div>
											<p>
												Form Submitted By:{" "}
												{workReport.formSubmittedBy}
											</p>
										</div>
									);
								})}
							</div>
						) : (
							<p>No work reports submitted</p>
						)}
					</div>
					{/* Invoicing is only shown for 09 and 10 */}
					<div className={showInvoicing ? "visible" : "hide"}>
						<div className='invoiceHeader'>
							<h2>Invoice</h2>
							<div className='invoiceContainer'>
								<Button
									variant='contained'
									color='primary'
									onClick={handleSubmit(onSendInvoice)}
								>
									Send Invoice to Customer
								</Button>
							</div>
						</div>
						<div>
							<Controller
								name='invoiceNumber'
								control={control}
								defaultValue={values.invoiceNumber}
								as={
									<TextField
										disabled
										label='Invoice Number'
										variant='outlined'
										type='text'
										style={{ width: "100%" }}
									/>
								}
							/>
						</div>

						<div>
							<Controller
								name='pricing'
								control={control}
								defaultValue={values.pricing}
								as={
									<TextField
										disabled
										label='Pricing'
										variant='outlined'
										type='text'
										style={{ width: "100%" }}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													$
												</InputAdornment>
											),
										}}
									/>
								}
							/>
						</div>
						<div>
							<Controller
								name='subTotal'
								control={control}
								defaultValue={values.subTotal}
								as={
									<TextField
										disabled
										label='Sub Total'
										variant='outlined'
										type='text'
										style={{ width: "100%" }}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													$
												</InputAdornment>
											),
										}}
									/>
								}
							/>
						</div>
						<div>
							<Controller
								name='gst'
								control={control}
								defaultValue={values.gst}
								as={
									<TextField
										disabled
										label='GST'
										variant='outlined'
										type='text'
										style={{ width: "100%" }}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													$
												</InputAdornment>
											),
										}}
									/>
								}
							/>
						</div>
						<div>
							<Controller
								name='total'
								control={control}
								defaultValue={values.total}
								as={
									<TextField
										disabled
										label='Total'
										variant='outlined'
										type='text'
										style={{ width: "100%" }}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													$
												</InputAdornment>
											),
										}}
									/>
								}
							/>
						</div>
						<div>
							<Controller
								name='invoiceDescription'
								control={control}
								defaultValue={values.invoiceDescription}
								render={(props) => (
									<TextField
										multiline
										label='Invoice Description'
										variant='outlined'
										type='text'
										style={{ width: "100%" }}
										defaultValue={props.value}
										onChange={(e) => {
											setHasUpdated(true);
											props.onChange(e.target.value);
										}}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				{/* This is only visible for (03) and (06) statuses  */}
				<div id='setCrew' className={showSetCrew ? "show" : "hide"}>
					{/* TODO: HIDE THIS UNTIL A CREW IS BEING SET */}
					<h2> Set Crew</h2>
					<p>
						{" "}
						Select up to 4 members - check the next page if you dont
						see the crew member
					</p>
					<div className='multiple'>
						<FormControl
							id='crewSelection'
							variant='outlined'
							style={{ width: "100%" }}
						>
							{/*<InputLabel id="select-setCrew-label">Select Crew</InputLabel>*/}
							<Controller
								id='select-setCrew'
								name='setCrew'
								control={control}
								defaultValue={crewData}
								rules={{
									required: true,
									// TODO: Need to refactor the render so that when they don't need to select a crew, that this form is not validate this part.
									//       Ideally, don't render this part of the form if its not necessary (currently just being hidden with CSS)
									//       When making this change, check to make sure that onSubmit still get's the data it needs and doesn't break
									// validate: () => {
									//     return getValues('setCrew') != '' && getValues('setCrew') != 'Select Crew';
									// }
								}}
							/>
							<div style={{ height: 340, width: "100%" }}>
								<DataGrid
									rows={crewData}
									columns={columns}
									pageSize={4}
									checkboxSelection
									onSelectionChange={(newSelection) => {
										setValue(
											"setCrew",
											newSelection.rowIds
										);
									}}
								/>
							</div>
						</FormControl>
					</div>
					{errors.setCrew && (
						<p className='errorText'> Please select a crew!</p>
					)}
				</div>
				<div
					id='newCrew'
					className={showReassignCrew ? "show" : "hide"}
				>
					{/* TODO: HIDE THIS UNTIL A CREW IS REASSIGNED */}
					<h2>Re-assign Crew</h2>
					<p>
						{" "}
						Select up to 4 members - check the next page if you dont
						see the crew member
					</p>
					<div className='multiple'>
						<FormControl
							variant='outlined'
							style={{ width: "100%" }}
						>
							<InputLabel id='select-newCrew-label'>
								Crew
							</InputLabel>
							<Controller
								name='newCrew'
								control={control}
								defaultValue={values.setCrew}
								rules={
									{
										// Validate that the they have selected a new crew.
										// TODO: Need to refactor the render so that when they don't need to select a crew, that this form is not validate this part.
										//       Ideally, don't render this part of the form if its not necessary (currently just being hidden with CSS)
										//       When making this change, check to make sure that onSubmit still get's the data it needs and doesn't break
										// validate: () => {
										//     return getValues('setCrew') != '' && getValues('setCrew') != 'Select Crew';
										// }
									}
								}
							/>
							<div style={{ height: 340, width: "100%" }}>
								<DataGrid
									rows={crewData}
									columns={columns}
									pageSize={5}
									checkboxSelection
									onSelectionChange={(newSelection) => {
										setValue(
											"setCrew",
											newSelection.rowIds
										);
									}}
								/>
							</div>
						</FormControl>
					</div>
				</div>
				<div>
					<Button
						variant='contained'
						color='primary'
						onClick={handleSubmit(onSubmit)}
					>
						Confirm Changes
					</Button>
					{submitError && <span>{submitError}</span>}
				</div>
			</form>
			{/* Snackbar Warning */}
			<Snackbar open={snackbarWarningOpen} onClose={handleClose}>
				<Alert onClose={handleClose} severity='warning'>
					PDFs Regenerating. Please wait for this process to complete.
				</Alert>
			</Snackbar>
			{/* Snackbar Error */}
			<Snackbar open={snackbarErrorOpen} autoHideDuration={6000}>
				<Alert severity='error'>{snackbarError}</Alert>
			</Snackbar>
		</div>
	);
}

// changes here as google image display is now broken
// temporary fix, as lh4 google proxy for google drive images may be taken down without notice
const PhotoThumbnail = (prop) => (
	<div className='imageThumbnail flex'>
		<a href={prop.url}>
			<img
				crossOrigin='anonymous'
				target='_blank'
				alt={prop.caption}
				src={prop.url.replace(
					"https://drive.google.com/uc?id=",
					"https://lh4.googleusercontent.com/d/"
				)}
			/>
		</a>
		<p>{prop.caption}</p>
	</div>
);
