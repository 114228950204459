import React, { forwardRef, useState, useEffect } from "react";
import { getWorkRequestData, getCrewData } from "../Firestore/Firestore.js";

import "./Table.css";

import MaterialTable from "material-table";
import { MTableToolbar } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";


import Modal from "../Modal/Modal";

import {
  updateCrewManagementSheet,
  addCrewManagementSheet,
  clearCrewManagementSheet,
} from "../../gapiFunctions";


/**
 * This object contains the Material-UI icons used in the Material-Table component.
 * Each key-value pair represents an icon and its corresponding component.
 *
 * @constant
 * @type {Object}
 * @property {React.Component} Add - The AddBox icon component.
 * @property {React.Component} Check - The Check icon component.
 * @property {React.Component} Clear - The Clear icon component.
 * @property {React.Component} Delete - The DeleteOutline icon component.
 * @property {React.Component} DetailPanel - The ChevronRight icon component.
 * @property {React.Component} Edit - The Edit icon component.
 * @property {React.Component} Export - The SaveAlt icon component.
 * @property {React.Component} Filter - The FilterList icon component.
 * @property {React.Component} FirstPage - The FirstPage icon component.
 * @property {React.Component} LastPage - The LastPage icon component.
 * @property {React.Component} NextPage - The ChevronRight icon component.
 * @property {React.Component} PreviousPage - The ChevronLeft icon component.
 * @property {React.Component} ResetSearch - The Clear icon component.
 * @property {React.Component} Search - The Search icon component.
 * @property {React.Component} SortArrow - The ArrowDownward icon component.
 * @property {React.Component} ThirdStateCheck - The Remove icon component.
 * @property {React.Component} ViewColumn - The ViewColumn icon component.
 */
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

/**
 * A functional component that renders a Material Table with editable rows.
 * The table data is fetched based on the provided table title and is updated whenever the table title changes.
 * The component also handles row updates, additions, and deletions.
 * Two table formats are supported: "Crew Management" and "Work Requests". 
 *
 * @param {Object} props - The component props.
 * @param {string} props.tableTitle - The title of the table.
 * @param {Array} props.tableColumnsState - The columns of the table.
 * @param {boolean} props.allowRefresh - Whether to allow refreshing the table data.
 * @return {JSX.Element} The rendered table component.
 */
export default function Table(props) {
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [tableData, setTableData] = useState({});

  // Onclick handler for when the a specific project is opened via the "action/edit" button
  const handleClickOpen = async (rowData) => {
    // Set activePoNumber to the internal PO number so that it can be passed down to the modal.
    setRequestData(rowData);

    setOpenDialog(true);
  };

  // Onclick handler for the modal close button;
  const handleClose = () => {
    setOpenDialog(false);
    setWorkRequestData();
    setRequestData({});
  };

  /**
   * Fetches and updates all work request data.
   *
   * @return {Promise<void>} Resolves when the data has been updated.
   */
  const setWorkRequestData = async () => {
    setLoading(true);
    const result = await getWorkRequestData();
    setTableData(result);
    setLoading(false);
  };

  /**
   * A function that handles editable rows for the "Crew Member Management" table.
   *
   * @param {string} tableTitle - The title of the table.
   * @return {Object} An object containing functions for row update, add, and delete.
   */
  const handleEditable = (tableTitle) => {
    if (tableTitle === "Crew Member Management") {
      return {
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...tableData];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setTableData([...dataUpdate]);
              resolve();
            }, 1000);
          })
            .then(() => {
              updateCrewManagementSheet(newData, oldData);
            })
            .catch((err) => {
              console.log("Error during client info editing submission:", err);
            }),
        onRowAdd: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setTableData([...tableData, newData]);
              addCrewManagementSheet(newData);
              resolve();
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const index = oldData.tableData.id;
              clearCrewManagementSheet(oldData);
              const dataDelete = [...tableData];
              dataDelete.splice(index, 1);
              setTableData([...dataDelete]);
              resolve();
            }, 1000);
          }),
      };
    }
  };

  // Update the table data whenever the tableTitle prop gets updated.
  useEffect(() => {
    // Load data for work requests.
    setWorkRequestData();
    // Load crew data
    const setCrewData = async () => {
      await getCrewData().then(function (result) {
        setTableData(result);
      });
    };

    // Collect the table data based on the page title route.
    const getData = (tableTitle) => {
      if (tableTitle === "Crew Member Management") {
        setCrewData().then(() => setLoading(false));
      }
      if (tableTitle === "Work Requests") {
        setWorkRequestData().then(() => setLoading(false));
      }
    };

    getData(props.tableTitle);
  }, [props.tableTitle]);

  useEffect(() => {
    if (Object.keys(requestData).length !== 0) {
      const row = tableData.find((element) => {
        return element.internalPoNumber === requestData.internalPoNumber;
      });
      if (row) {
        setRequestData(row);
      }
    }
  }, [tableData]);

  if (loading) {
    return (
      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>
    ); // render null when app is not ready
  } else {
    return (
      <div className="table">
        <div className="table-control">
          <h4>Click the pencil icon to edit entries.</h4>
        </div>
        <MaterialTable
          icons={tableIcons}
          title={props.tableTitle}
          columns={props.tableColumnsState}
          data={tableData}
          options={{
            pageSize: 20,
            pageSizeOptions: [20, 40, 60],
            headerStyle: {
              backgroundColor: "#3B417C",
              color: "white",
            },
          }}
          editable={handleEditable(props.tableTitle)}
          actions={
            props.tableTitle === "Work Requests" && [
              {
                icon: Edit,
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                },
              },
            ]
          }
          components={props.allowRefresh ? {           
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0px 10px" }}>
                  <Button
                    onClick={() => {
                      setWorkRequestData();
                    }}
                    variant="contained"
                    color="primary"
                    style={{
                      marginBottom: "5px",
                    }}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Data
                  </Button>
                </div>
              </div>
            ),
          } : {}}
        />

        {openDialog && (
          <Modal
            requestData={requestData}
            handleClose={handleClose}
            setOpenDialog={setOpenDialog}
            open={openDialog}
            refreshRequest={setWorkRequestData}
          />
        )}
      </div>
    );
  }
}
