import _ from "lodash";
import moment from "moment";
import { db } from "../components/Firestore/Firestore.js";

/**
 * Regenerates the PDF documents based on the provided data.
 * The work request data is fetched from the Firestore database using the provided internal PO number to generate the the final report PDF.
 * This includes the image and caption arrays that are used to generate the final report PDF.
 * The invoice data is fetched from the Firestore database using the provided invoice number to generate the invoice PDF.
 * 
 * @param {object} data - The data object containing the internal PO number.
 * @return {Promise<void>} A promise that resolves when the PDF regeneration is complete.
 */
export async function regenPDF(data) {
	return new Promise(async (resolve, reject) => {
		var workRequestPayload = {};
		var reportPayload = [];
		var imageArray = [];
		var captionArray = [];

		var invoiceNumberIndex = 0;
		var invoicePayload = {};
		//console.log("incoming data",data);

		var finalReportPayload = {};

		//Work Request Data
		const workRequest = db
			.collection("workRequest")
			.doc(data.internalPoNumber);
		const workRequestData = await workRequest.get();
		if (!workRequestData.exists) {
			console.log("No such document!");
		} else {
			
			// gather data for work request from database, data supplied to function (input) and push to payload
			const WRD = workRequestData.data();
			workRequestPayload["HandshakeKey"] = "asdf123";
			workRequestPayload["FormID"] = data.internalPoNumber;
			workRequestPayload["UniqueID"] = data.internalPoNumber;
			workRequestPayload["Job Status"] = WRD.jobStatus;
			workRequestPayload["Type"] = WRD.workOrderType;
			workRequestPayload["Customer PO Number"] = WRD.customerPoNumber;
			workRequestPayload["Client Name"] = WRD.clientName;
			workRequestPayload["Billing Address"] = WRD.billingAddress;
			workRequestPayload["Location Name"] = WRD.locationName;
			workRequestPayload["Work Address"] = WRD.workAddress;
			workRequestPayload["Requested By"] = WRD.requestedBy;
			workRequestPayload["Contact Number"] = WRD.contactNumber;
			workRequestPayload["Email"] = WRD.email;
			workRequestPayload["Called In Date"] = WRD.calledInDate;
			workRequestPayload["Additional Information"] =
				WRD.additionalInformation;

			finalReportPayload["HandshakeKey"] = "asdf123";
			finalReportPayload["FormID"] = data.internalPoNumber;
			finalReportPayload["Internal PO Number"] = data.internalPoNumber;
			finalReportPayload["Job Status"] = WRD.jobStatus;
			finalReportPayload["Customer PO Number"] = WRD.customerPoNumber;
			finalReportPayload["Client Name"] = WRD.clientName;
			finalReportPayload["Location Name"] = WRD.locationName;
			finalReportPayload["Work Address"] = WRD.workAddress;
			finalReportPayload["Requested by"] = WRD.requestedBy;
			finalReportPayload["Contact Number"] = WRD.contactNumber;
			finalReportPayload["Called In Date"] = new Date(WRD.calledInDate)
				.toLocaleString("en-us", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				})
				.replace(/(\d+)\/(\d+)\/(\d+)/, "$1/$2/$3");
		}
		console.log("WRPayload-", workRequestPayload);

		//Work Report Data
		const workReports = db
			.collection("workRequest")
			.doc(data.internalPoNumber)
			.collection("workReport");
		const snapshot = await workReports.get();

		// TODO: Review if this is neccessay - goiing through all the old work reports
		snapshot.forEach(async (doc) => {
			var report = {};
			//console.log(doc.id, '=>', doc.data());
			const RPD = doc.data();

			report["HandshakeKey"] = "asdf123";
			report["FormID"] = data.internalPoNumber;
			report["Internal PO Number"] = data.internalPoNumber;
			report["Customer PO Number"] =
				workRequestData.data().customerPoNumber;
			report["Job Status"] = RPD.jobStatus;
			report["Client Name"] = RPD.clientName;
			report["Location Name"] = RPD.locationName;
			report["Work Address"] = RPD.workAddress;
			report["Requested by"] = RPD.requestedBy;
			report["Contact Number"] = RPD.contactNumber;
			report["Called In Date"] = RPD.calledInDate;
			report["Date on Site"] = RPD.dateOnSite;
			report["Completion Date"] = RPD.completionDate;
			report["Start Time"] = RPD.crewStartTime;
			report["End Time"] = RPD.crewEndTime;
			report["Hours on site"] = RPD.crewHoursOnSite;
			report["Number of Photos to Upload"] = RPD.numberOfPhotos;
			report["Signature"] = RPD.signature;
			report["Form Submitted By"] = RPD.formSubmittedBy;
			report["Work Description"] = RPD.workDescription;

			//Gets the photos
			const photos = db
				.collection("workRequest")
				.doc(data.internalPoNumber)
				.collection("workReport")
				.doc(doc.id)
				.collection("photos");
			const photosData = await photos.get();

			var photoIndex = 0;
			for (let i = 1; i < 21; i++) {
				report["Photo #" + i] = "";
				report["Caption #" + i] = "";
			}

			photosData.forEach((photo) => {
				//console.log(photo.id, " Photos =>", photo.data());
				var PD = photo.data();
				imageArray.push(PD.link);
				captionArray.push(PD.caption);
				report["Photo #" + (photoIndex + 1)] = PD.link;
				report["Caption #" + (photoIndex + 1)] = PD.caption;
				photoIndex += 1;
			});

			//Gets the Crew
			const crewMembersData = db
				.collection("workRequest")
				.doc(data.internalPoNumber)
				.collection("workReport")
				.doc(doc.id)
				.collection("crew");
			const crewData = await crewMembersData.get();

			var crewIndex = 0;
			crewData.forEach((crew) => {
				//console.log(crew.id, " Crew =>", crew.data());
				var CD = crew.data();
				report["Crew Member " + (crewIndex + 1)] = CD.crewMember
					? CD.crewMember
					: "";
				report["Start Time " + (crewIndex + 1)] = CD.startTime
					? CD.startTime
					: "";
				report["End Time " + (crewIndex + 1)] = CD.endTime
					? CD.endTime
					: "";
				report["Hours on site " + (crewIndex + 1)] = CD.hoursOnSite
					? CD.hoursOnSite
					: "";
				crewIndex += 1;
			});
			reportPayload.push(report);
		});

		console.log("report payload", reportPayload);

		//Invoice Data
		const invoiceNumberLink = db
			.collection("workRequest")
			.doc(data.internalPoNumber)
			.collection("invoiceId");
		const invoiceNumber = await invoiceNumberLink.get();
		invoiceNumber.forEach(async (invoice) => {
			//console.log(invoice.id);
			invoiceNumberIndex = invoice.id;
		});
		//console.log(invoice.id);
		if (invoiceNumberIndex !== 0) {
			const invoiceCollection = db
				.collection("invoices")
				.doc(invoiceNumberIndex);
			const invoiceData = await invoiceCollection.get();
			if (!invoiceData.exists) {
				//console.log("no invoices!")
			} else {
				//console.log ("invoice data: ", invoiceData.data());
				const ID = invoiceData.data();
				invoicePayload["HandshakeKey"] = "asdf123";
				invoicePayload["FormID"] = data.internalPoNumber;
				invoicePayload["Internal PO Number"] = ID.internalPoNumber;
				invoicePayload["Invoice Number"] = invoiceNumberIndex;
				invoicePayload["Job Status"] = ID.jobStatus;
				invoicePayload["Customer PO Number"] = ID.customerPoNumber;
				invoicePayload["Invoice Date"] = ID.invoiceDate;
				invoicePayload["Client Name"] = ID.clientName;
				invoicePayload["Location Name"] = ID.locationName;
				invoicePayload["Billing Address"] = ID.billingAddress;
				invoicePayload["Work Address"] = ID.workAddress;
				invoicePayload["Description"] = ID.description;
				invoicePayload["Pricing"] = ID.pricing;
				invoicePayload["Subtotal"] = ID.subtotal;
				invoicePayload["GST"] = ID.gst;
				invoicePayload["Total"] = ID.total;

				finalReportPayload["Description"] = ID.description;
				finalReportPayload["Submitted By"] = ID.submittedBy
					? ID.submittedBy
					: "";
			}
		}

		//Create Final Report Data
		if (reportPayload.length > 0) {
			const lastReport = reportPayload[reportPayload.length - 1];
			var formattedDOS = new Date(lastReport["Date on Site"])
				.toLocaleString("en-us", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				})
				.replace(/(\d+)\/(\d+)\/(\d+)/, "$1/$2/$3");
			var formattedCD = new Date(lastReport["Completion Date"])
				.toLocaleString("en-us", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				})
				.replace(/(\d+)\/(\d+)\/(\d+)/, "$1/$2/$3");

			finalReportPayload["Date on Site"] = formattedDOS;
			finalReportPayload["Completion Date"] = formattedCD;

			finalReportPayload["Signature"] = lastReport["Signature"];
		}

		const workRequestImages = db
			.collection("workRequest")
			.doc(data.internalPoNumber)
			.collection("finalImages")
			.doc("images");
		const workRequestImagesData = await workRequestImages.get();
		if (!workRequestImagesData.exists) {
			console.log("No Document Exist");
			if (imageArray.length > 20) {
				const slicedImageArray = imageArray.slice(
					Math.max(imageArray.length - 20, 1)
				);
				const slicedCaptionArray = captionArray.slice(
					Math.max(captionArray.length - 20, 1)
				);
				finalReportPayload["photoArray"] = createPhotoArray(
					slicedImageArray,
					slicedCaptionArray
				);
			} else {
				// less than 20
				// finalReportPayload["images"] = imageArray;
				// finalReportPayload["captions"] = captionArray;
				finalReportPayload["photoArray"] = createPhotoArray(
					imageArray,
					captionArray
				);
			}
		} else {
			const WRID = workRequestImagesData.data();
			finalReportPayload["images"] = WRID.images;
			finalReportPayload["captions"] = WRID.captions;
			finalReportPayload["photoArray"] = createPhotoArray(
				WRID.images,
				WRID.captions
			);
			console.log(WRID);
		}

		console.log("FRPayload-", finalReportPayload);

		// generate the report names
		const finalReportFileName = generateFinalReportName(finalReportPayload);
		const invoiceFileName = generateInvoiceReportName(invoicePayload);


		// TODO: Review if we can use camelcase before it gets here
		const camelCaseFinalReportPayload = _.mapKeys(
			finalReportPayload,
			(v, k) => _.camelCase(k)
		);
		const camelCaseInvoicePayload = _.mapKeys(invoicePayload, (v, k) =>
			_.camelCase(k)
		);

		const finalReportGenBody = createFinalReportRequestPayload(
			camelCaseFinalReportPayload,
			finalReportFileName
		);
		const invoiceGenBody = createInvoiceRequestPayload(
			camelCaseInvoicePayload,
			invoiceFileName
		);

		try {
			if (Object.keys(invoicePayload).length > 0) {
				await fetch(
					"https://api.pdfmonkey.io/api/v1/documents",
					invoiceGenBody
				);
				console.log("Posted Invoice Successfully", invoicePayload);
			} else {
				console.log("Posted failed");
			}
		} catch (err) {
			console.log(err);
			reject();
		}

		await new Promise((r) => setTimeout(r, 10000));
		try {
			await fetch(
				"https://api.pdfmonkey.io/api/v1/documents",
				finalReportGenBody
			);
			console.log("Posted Final Report Successfully", finalReportPayload);
		} catch (err) {
			console.log(err);
			reject();
		}
		
		await new Promise((r) => setTimeout(r, 30000));
		console.log("transaction done");
		resolve();
	});
}

/**
 * Generates a file name for a final report based on the given payload.
 *
 * @param {Object} payload - The payload containing information for generating the file name.
 * @param {string} payload["Location Name"] - The name of the location.
 * @param {string} payload["Job Status"] - The status of the job.
 * @param {string} payload["Date on Site"] - The date on site for temporary repair.
 * @param {string} payload["Completion Date"] - The completion date for work completed and work in progress.
 * @param {string} payload["Internal PO Number"] - The internal purchase order number.
 * @return {string} The generated file name for the final report.
 */
const generateFinalReportName = (payload) => {
	let newFileName = "";
	let dateString = moment().format("yyyy-MM-DD");
	const locationName = payload["Location Name"] ?? "";
	const sanitizedLocationName = locationName.replace(/[/\\|?*<>:"]/g, " ");
	console.log("Job Status", payload["Job Status"]);
	console.log("payload", payload);
	if (payload["Job Status"] === "(05) Temporary Repair") {
		if (moment(payload["Date on Site"]).isValid()) {
			dateString = moment(payload["Date on Site"]).format("yyyy-MM-DD");
		}
	} else if (payload["Job Status"] === "(07) Work Completed") {
		if (moment(payload["Completion Date"]).isValid()) {
			dateString = moment(payload["Completion Date"]).format(
				"yyyy-MM-DD"
			);
		}
	} else if (payload["Job Status"] === "(05) Work In Progress") {
		if (moment(payload["Completion Date"]).isValid()) {
			dateString = moment(payload["Completion Date"]).format(
				"yyyy-MM-DD"
			);
		}
	} else {
		if (payload["Completion Date"]) {
			if (moment(payload["Completion Date"]).isValid()) {
				dateString = moment(payload["Completion Date"]).format(
					"yyyy-MM-DD"
				);
			}
		}
	}
	newFileName = `${sanitizedLocationName} - ${dateString} - PO#${payload["Internal PO Number"]} -  Final Report`;
	console.log("newFileName", newFileName);
	return newFileName;
};

/**
 * Generates a file name for an invoice report based on the provided payload.
 *
 * @param {Object} payload - The payload containing information for generating the file name.
 * @param {string} payload["Location Name"] - The name of the location.
 * @param {string} payload["Invoice Number"] - The invoice number.
 * @param {string} payload["Internal PO Number"] - The internal purchase order number.
 * @return {string} The generated file name for the invoice report.
 */
const generateInvoiceReportName = (payload) => {
	const locationName = payload["Location Name"] ?? "";
	const sanitizedLocationName = locationName.replace(/[/\\|?*<>:"]/g, " ");

	const newFileName = `Invoice #${payload["Invoice Number"]} - ${sanitizedLocationName} - PO# ${payload["Internal PO Number"]}`;
	return newFileName;
};

/**
 * Creates an array of photo objects from the provided images and captions.
 * This array is used on PDF Monkey to capture the images and captions for the final report.
 *
 * @param {string[]} images - An array of image URLs.
 * @param {string[]} captions - An array of image captions.
 * @return {Object[]} An array of objects containing the fixed image URL and caption.
 */
const createPhotoArray = (images, captions) => {
	const photoArray = [];
	for (let i = 0; i < images.length; i++) {
		let url = images[i];
		let fixedUrl = url.replace(
			"https://drive.google.com/uc?id=",
			"https://lh4.googleusercontent.com/d/"
		);
		let caption = captions[i];
		photoArray.push({ url: fixedUrl, caption });
	}
	return photoArray;
};

/**
 * Creates a payload for an invoice request to PDF Monkey.
 *
 * @param {Object} payloadBody - The body of the payload.
 * @param {string} invoiceReportName - The name of the invoice report.
 * @return {Object} The created payload.
 */
const createInvoiceRequestPayload = (payloadBody, invoiceReportName) => {
	return {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: "Bearer VqxiVM-F9yHrsr36YNcA",
		},
		body: JSON.stringify({
			document_template_id: "7C4AB385-31B9-4FD9-B9AB-AC278423F5A2",
			status: "pending",
			payload: {
				...payloadBody,
			},
			meta: {
				clientId: "LOCAL-TEST",
				_filename: invoiceReportName,
				reportType: "invoice",
				internalPONumber: payloadBody.internalPoNumber,
			},
		}),
	};
};

/**
 * Creates a payload for a final report request to PDF Monkey.
 *
 * @param {Object} payloadBody - The body of the payload.
 * @param {string} invoiceReportName - The name of the invoice report.
 * @return {Object} The created payload.
 */
const createFinalReportRequestPayload = (payloadBody, invoiceReportName) => {
	return {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: "Bearer VqxiVM-F9yHrsr36YNcA",
		},
		body: JSON.stringify({
			document_template_id: "FD1BF1B2-C065-467D-B0BE-C0584F3B6FA4",
			status: "pending",
			payload: {
				...payloadBody,
			},
			meta: {
				clientId: "LOCAL-TEST",
				_filename: invoiceReportName,
				reportType: "finalReport",
				internalPONumber: payloadBody.internalPoNumber,
			},
		}),
	};
};
