/**
 * Firestore.js
 * This file initializes the Firebase app and provides the Firestore instance.
 * The Firestore instance can be used to interact with the Firestore database.
 * 
 * Methods:
 * 1. getWorkRequests: Retrieves all work requests from the Firestore database.
 * 2. getWorkReport: Retrieves a specific work report from the Firestore database.
 * 3. getWorkRequest: Retrieves a specific work request from the Firestore database.
 * 
 */
import firebase from "firebase";


/**
 * Initialize the Firebase app with the provided configuration.
 *
 * @param {Object} config - The Firebase configuration object.
 * @param {string} config.apiKey - The Firebase API key.
 * @param {string} config.authDomain - The Firebase authentication domain.
 * @param {string} config.databaseURL - The Firebase Realtime Database URL.
 * @param {string} config.projectId - The Firebase project ID.
 * @param {string} config.storageBucket - The Firebase storage bucket.
 * @param {string} config.messagingSenderId - The Firebase messaging sender ID.
 * @param {string} config.appId - The Firebase app ID.
 * @param {string} [config.measurementId] - The Firebase measurement ID.
 * @returns {Object} - The initialized Firebase app.
 */
const firebaseApp = firebase.initializeApp({
  // ---------prod----------
  apiKey: "AIzaSyAr3GwlqGYL6TUH77uzYAfp0XCfs7eXZc8", // Need to hide the key.
  apiKey: "AIzaSyAr3GwlqGYL6TUH77uzYAfp0XCfs7eXZc8", //Need to hide the key.
  authDomain: "knights-roofing-dev.firebaseapp.com",
  databaseURL: "https://knights-roofing-dev.firebaseio.com",
  projectId: "knights-roofing-dev",
  storageBucket: "knights-roofing-dev.appspot.com",
  messagingSenderId: "101776829635",
  appId: "1:101776829635:web:6141a6c98b70c3fcf44676",
  measurementId: "G-G2XPER4X88",

  // --------------dev-----------
  // apiKey: "AIzaSyDxCNTgPDKTaVYA3d3n7O4iDR_1LwCR1GY",
  // authDomain: "knights-roofing-1599666614144.firebaseapp.com",
  // databaseURL: "https://knights-roofing-1599666614144.firebaseio.com",
  // projectId: "knights-roofing-1599666614144",
  // storageBucket: "knights-roofing-1599666614144.appspot.com",
  // messagingSenderId: "54308692394",
  // appId: "1:54308692394:web:a436da8e8dc88a6a4d0bd3",
  // measurementId: "G-2J5Z9HCYRE"
});


/**
 * Retrieves the Firestore instance associated with the initialized Firebase app.
 * 
 */
const db = firebaseApp.firestore();

/**
 * Retrieves work request data from the Firestore database and sorts it by job status.
 *
 * @return {Promise<Array>} A promise that resolves to an array of sorted work request data.
 * @throws {Object} An object containing an error message and error information if an error occurs.
 */
async function getWorkRequestData() {
  return new Promise(async (resolve, reject) => {
    try {
      // Initialize an empty array to store the retrieved data
      var allData = [];

      // FIXME - one loop? 
      // Retrieve data from the "workRequest" collection in Firestore
      await db
        .collection("workRequest")
        .get()
        .then((querySnapshot) => {
          // Map the query snapshot to an array of data objects
          const data = querySnapshot.docs.map((doc) => doc.data());
          // Map the query snapshot to an array of document IDs
          const dataId = querySnapshot.docs.map((doc) => doc.id);

          // Iterate over the data array and assign the document ID to the "internalPoNumber" property
          for (let i = 0; i < data.length; i++) {
            data[i].internalPoNumber = dataId[i];
            // Push the data object to the allData array
            allData.push(data[i]);
          }
        });

      // Initialize an empty array to store the sorted data
      var workRequestData = [];

      // Iterate over the allData array and create a new object with specific properties
      for (let i = 0; i < allData.length; i++) {
        workRequestData.push({
          internalPoNumber: allData[i].internalPoNumber
            ? allData[i].internalPoNumber
            : "",
          customerPoNumber: allData[i].customerPoNumber
            ? allData[i].customerPoNumber
            : "",
          clientName: allData[i].clientName ? allData[i].clientName : "",
          contactNumber: allData[i].contactNumber
            ? allData[i].contactNumber
            : "",
          invoiceEmailAddress: allData[i].email ? allData[i].email : "",
          jobStatus: allData[i].jobStatus ? allData[i].jobStatus : "",
          locationName: allData[i].locationName ? allData[i].locationName : "",
          billingAddress: allData[i].billingAddress
            ? allData[i].billingAddress
            : "",
          workAddress: allData[i].workAddress ? allData[i].workAddress : "",
          requestedBy: allData[i].requestedBy ? allData[i].requestedBy : "",
          reportEmailAddress: allData[i].reportEmail
            ? allData[i].reportEmail
            : "",
          workOrderType: allData[i].workOrderType
            ? allData[i].workOrderType
            : "",
          calledInDate: allData[i].calledInDate ? allData[i].calledInDate : "",
          activeCrew: allData[i].activeCrew ? allData[i].activeCrew : "",
          additionalInformation: allData[i].additionalInformation
            ? allData[i].additionalInformation
            : "",
          workDescription: allData[i].workDescription
            ? allData[i].workDescription
            : "",
          PDFS: allData[i].PDFS ? allData[i].PDFS : "",
        });
      }

      /**
       * Compares two work request objects based on their job status.
       *
       * @param {Object} a - The first work request object.
       * @param {Object} b - The second work request object.
       * @return {number} A number indicating the relative order of the two objects.
       */
      const compare = (a, b) => {
        // Use a regular expression to extract the number from the job status string
        const regex = /[^([]+(?=\))/;
        const jobA = a.jobStatus.toUpperCase();
        const jobB = b.jobStatus.toUpperCase();
        var numA = jobA.match(regex)[0];
        var numB = jobB.match(regex)[0];

        let comparison = 0;
        if (numA > numB) {
          comparison = 1;
        } else if (numA < numB) {
          comparison = -1;
        }
        return comparison;
      };

      // Sort the workRequestData array based on the job status using the compare function
      const sortedData = workRequestData.sort(compare);

      // Resolve the promise with the sorted data
      resolve(sortedData);
    } catch (e) {
      // Reject the promise with an error object containing the error message and error information
      reject({
        errorMessage: e,
        error: "error message",
      });
    }
  });
}



/**
 * Retrieves all the crew data from the "Crews" collection in Firestore.
 *
 * @return {Promise<Array<Object>>} A promise that resolves with an array of crew data objects.
 *                                  Each object contains the email and name of a crew member.
 *                                  If a crew member does not have a name, an empty string is used.
 * @throws {Object} An error object with an error message if the retrieval fails.
 */
async function getCrewData() {
  return new Promise(async (resolve, reject) => {
    try {
      // Retrieve all data from the "Crews" collection in Firestore
      const snapshot = await db.collection("Crews").get();

      // Initialize an empty array to store the retrieved data
      const allData = [];

      // FIXME - one loop? 
      // Iterate over the documents in the query snapshot and extract the data
      snapshot.forEach((doc) => {
        const data = doc.data();
        data.email = doc.id;
        allData.push(data);
      });

      // Initialize an empty array to store the crew data objects
      const crewData = [];

      // Iterate over the allData array and create crew data objects
      for (let i = 0; i < allData.length; i++) {
        const crewMember = {
          email: allData[i].email,
          name: allData[i].name || "", // Use the logical OR operator for default value
        };
        crewData.push(crewMember);
      }

      // Resolve the promise with the crew data array
      resolve(crewData);
    } catch (error) {
      // Reject the promise with an error object containing the error message
      reject({
        errorMessage: error.message,
        error: "error message",
      });
    }
  });
}

/**
 * Retrieves invoice data from the Firestore database based on the provided data ID.
 *
 * @param {string} dataID - The ID of the data to retrieve.
 * @return {Promise<object>} A promise that resolves to an object containing the invoice data.
 * The object has the following properties:
 * - internalPoNumber: The internal PO number of the invoice.
 * - invoiceNumber: The invoice number.
 * - customerPoNumber: The customer PO number.
 * - jobStatus: The job status.
 * - invoiceDate: The invoice date.
 * - clientName: The client name.
 * - locationName: The location name.
 * - billingAddress: The billing address.
 * - workAddress: The work address.
 * - description: The description.
 * - pricing: The pricing.
 * - subTotal: The subtotal.
 * - gst: The GST.
 * - total: The total.
 * @throws {Promise<object>} A promise that rejects with an object containing an error message.
 */
async function getInvoiceData(dataID) {
  return new Promise(async (resolve, reject) => {
    try {
      // Initialize an empty array to store the retrieved data
      var allData = [];

      // FIXME - one loop? / Can we just query for the particular invoice and not iterate? 
      // Retrieve data from the "invoices" collection in Firestore
      await db.collection("invoices").get().then((querySnapshot) => {
        // Map the query snapshot to an array of data objects
        const data = querySnapshot.docs.map((doc) => doc.data());
        const invoiceId = querySnapshot.docs.map((doc) => doc.id);

        // Iterate over the data array and assign the invoice number to the respective invoice data
        if (data.length > 0) {
          for (let j = 0; j < data.length; j++) {
            if (dataID === data[j].internalPoNumber) {
              data[j].invoiceNumber = invoiceId[j];
              allData.push(data[j]);
            }
          }
        }
      });

      // Initialize an empty object to store the invoice data
      var invoiceData = {};

      // Iterate over the allData array and create a new object with specific properties
      for (let i = 0; i < allData.length; i++) {
        invoiceData = {
          internalPoNumber: allData[i].internalPoNumber
            ? allData[i].internalPoNumber
            : "",
          invoiceNumber: allData[i].invoiceNumber
            ? allData[i].invoiceNumber
            : "",
          customerPoNumber: allData[i].customerPoNumber
            ? allData[i].customerPoNumber
            : "",
          jobStatus: allData[i].jobStatus ? allData[i].jobStatus : "",
          invoiceDate: allData[i].invoiceDate ? allData[i].invoiceDate : "",
          clientName: allData[i].clientName ? allData[i].clientName : "",
          locationName: allData[i].locationName ? allData[i].locationName : "",
          billingAddress: allData[i].billingAddress
            ? allData[i].billingAddress
            : "",
          workAddress: allData[i].workAddress ? allData[i].workAddress : "",
          description: allData[i].description ? allData[i].description : "",
          pricing: allData[i].pricing ? allData[i].pricing : "",
          subTotal: allData[i].subtotal ? allData[i].subtotal : "",
          gst: allData[i].gst ? allData[i].gst : "",
          total: allData[i].total ? allData[i].total : "",
        };
      }

      // Resolve the promise with the invoice data object
      resolve(invoiceData);
    } catch (error) {
      // Reject the promise with an error object containing the error message
      reject({
        errorMessage: error.message,
        error: "error message",
      });
    }
  });
}


/**
 * Retrieves report data from Firestore based on the provided data ID.
 *
 * @param {string} dataID - The ID of the data to retrieve.
 * @return {Promise<Array<Object>>} A promise that resolves to an array of report data objects.
 *                                  Each object represents a work report and contains various properties
 *                                  such as internalPoNumber, uniqueID, jobStatus, formSubmittedBy,
 *                                  clientName, locationName, workAddress, requestedBy, contactNumber,
 *                                  calledInDate, completionDate, dateOnSite, crewStartTime, crewEndTime,
 *                                  crewHoursOnSite, workDescription, numberOfPhotos, and various photo
 *                                  and caption properties.
 * @throws {Object} If there is an error retrieving the data, the promise is rejected with an error object.
 */
async function getReportData(dataID) {
  return new Promise(async (resolve, reject) => {
    try {
      var allData = [];

      // FIXME - There should be a better way of doing this nested loop
      await db
        .collection("workRequest")
        .get()
        .then(async (querySnapshot) => {
          await db
            .collection("workRequest")
            .doc(dataID)
            .collection("workReport")
            .get()
            .then(async (querySnapshot) => {
              const data = querySnapshot.docs.map((doc) => doc.data());
              const reportId = querySnapshot.docs.map((doc) => doc.id);
              if (reportId.length > 0) {
                for (let j = 0; j < reportId.length; j++) {
                  data[j].internalPoNumber = dataID;
                  data[j].uniqueId = reportId[j];
                  allData.push(data[j]);
                  //Get the photos for the work report
                  await db
                    .collection("workRequest")
                    .doc(dataID)
                    .collection("workReport")
                    .doc(reportId[j])
                    .collection("photos")
                    .get()
                    .then((querySnapshot) => {
                      const data2 = querySnapshot.docs.map((doc) => doc.data());
                      for (let k = 0; k < data2.length; k++) {
                        data[j]["photo" + (k + 1)] = data2[k].link;
                        data[j]["caption" + (k + 1)] = data2[k].caption;
                      }
                    });
                  //get the crew hours for the work report
                  await db
                    .collection("workRequest")
                    .doc(dataID)
                    .collection("workReport")
                    .doc(reportId[j])
                    .collection("crew")
                    .get()
                    .then((querySnapshot) => {
                      const data3 = querySnapshot.docs.map((doc) => doc.data());
                      const crewId = querySnapshot.docs.map((doc) => doc.id);
                      for (let u = 0; u < data3.length; u++) {
                        data[j]["crew"] = crewId;
                        data[j]["crew" + (u + 1)] = data3[u].crewMember;
                        data[j]["crew" + (u + 1) + "EndTime"] =
                          data3[u].endTime;
                        data[j]["crew" + (u + 1) + "HoursOnSite"] =
                          data3[u].hoursOnSite;
                        data[j]["crew" + (u + 1) + "StartTime"] =
                          data3[u].startTime;
                      }
                    });
                }
              }
            });
        });
      var reportData = [];

      for (let i = 0; i < allData.length; i++) {
        reportData.push({
          internalPoNumber: allData[i].internalPoNumber
            ? allData[i].internalPoNumber
            : "",
          uniqueID: allData[i].uniqueId ? allData[i].uniqueId : "",
          jobStatus: allData[i].jobStatus ? allData[i].jobStatus : "",
          formSubmittedBy: allData[i].formSubmittedBy
            ? allData[i].formSubmittedBy
            : "",
          clientName: allData[i].clientName ? allData[i].clientName : "",
          locationName: allData[i].locationName ? allData[i].locationName : "",
          workAddress: allData[i].workAddress ? allData[i].workAddress : "",
          requestedBy: allData[i].requestedBy ? allData[i].requestedBy : "",
          contactNumber: allData[i].contactNumber
            ? allData[i].contactNumber
            : "",
          calledInDate: allData[i].calledInDate ? allData[i].calledInDate : "",
          completionDate: allData[i].completionDate
            ? allData[i].completionDate
            : "",
          dateOnSite: allData[i].dateOnSite ? allData[i].dateOnSite : "",
          crewStartTime: allData[i].crewStartTime
            ? allData[i].crewStartTime
            : "",
          crewEndTime: allData[i].crewEndTime ? allData[i].crewEndTime : "",
          crewHoursOnSite: allData[i].crewHoursOnSite
            ? allData[i].crewHoursOnSite
            : "",
          crew1: allData[i].crew1 ? allData[i].crew1 : "",
          crew1StartTime: allData[i].crew1StartTime
            ? allData[i].crew1StartTime
            : "",
          crew1EndTime: allData[i].crew1EndTime ? allData[i].crew1EndTime : "",
          crew1HoursOnSite: allData[i].crew1HoursOnSite
            ? allData[i].crew1HoursOnSite
            : "",
          crew2: allData[i].crew2 ? allData[i].crew2 : "",
          crew2StartTime: allData[i].crew2StartTime
            ? allData[i].crew2StartTime
            : "",
          crew2EndTime: allData[i].crew2EndTime ? allData[i].crew2EndTime : "",
          crew2HoursOnSite: allData[i].crew2HoursOnSite
            ? allData[i].crew2HoursOnSite
            : "",
          crew3: allData[i].crew3 ? allData[i].crew3 : "",
          crew3StartTime: allData[i].crew3StartTime
            ? allData[i].crew3StartTime
            : "",
          crew3EndTime: allData[i].crew3EndTime ? allData[i].crew3EndTime : "",
          crew3HoursOnSite: allData[i].crew3HoursOnSite
            ? allData[i].crew3HoursOnSite
            : "",
          crew4: allData[i].crew4 ? allData[i].crew4 : "",
          crew4StartTime: allData[i].crew4StartTime
            ? allData[i].crew4StartTime
            : "",
          crew4EndTime: allData[i].crew4EndTime ? allData[i].crew4EndTime : "",
          crew4HoursOnSite: allData[i].crew4HoursOnSite
            ? allData[i].crew4HoursOnSite
            : "",
          workDescription: allData[i].workDescription
            ? allData[i].workDescription
            : "",
          numberOfPhotos: allData[i].numberOfPhotos
            ? allData[i].numberOfPhotos
            : "",
          photo1: allData[i].photo1 ? allData[i].photo1 : "",
          caption1: allData[i].caption1 ? allData[i].caption1 : "",
          photo2: allData[i].photo2 ? allData[i].photo2 : "",
          caption2: allData[i].caption2 ? allData[i].caption2 : "",
          photo3: allData[i].photo3 ? allData[i].photo3 : "",
          caption3: allData[i].caption3 ? allData[i].caption3 : "",
          photo4: allData[i].photo4 ? allData[i].photo4 : "",
          caption4: allData[i].caption4 ? allData[i].caption4 : "",
          photo5: allData[i].photo5 ? allData[i].photo5 : "",
          caption5: allData[i].caption5 ? allData[i].caption5 : "",
          photo6: allData[i].photo6 ? allData[i].photo6 : "",
          caption6: allData[i].caption6 ? allData[i].caption6 : "",
          photo7: allData[i].photo7 ? allData[i].photo7 : "",
          caption7: allData[i].caption7 ? allData[i].caption7 : "",
          photo8: allData[i].photo8 ? allData[i].photo8 : "",
          caption8: allData[i].caption8 ? allData[i].caption8 : "",
          photo9: allData[i].photo9 ? allData[i].photo9 : "",
          caption9: allData[i].caption9 ? allData[i].caption9 : "",
          photo10: allData[i].photo10 ? allData[i].photo10 : "",
          caption10: allData[i].caption10 ? allData[i].caption10 : "",
          photo11: allData[i].photo11 ? allData[i].photo11 : "",
          caption11: allData[i].caption11 ? allData[i].caption11 : "",
          photo12: allData[i].photo12 ? allData[i].photo12 : "",
          caption12: allData[i].caption12 ? allData[i].caption12 : "",
          photo13: allData[i].photo13 ? allData[i].photo13 : "",
          caption13: allData[i].caption13 ? allData[i].caption13 : "",
          photo14: allData[i].photo14 ? allData[i].photo14 : "",
          caption14: allData[i].caption14 ? allData[i].caption14 : "",
          photo15: allData[i].photo15 ? allData[i].photo15 : "",
          caption15: allData[i].caption15 ? allData[i].caption15 : "",
          photo16: allData[i].photo16 ? allData[i].photo16 : "",
          caption16: allData[i].caption16 ? allData[i].caption16 : "",
          photo17: allData[i].photo17 ? allData[i].photo17 : "",
          caption17: allData[i].caption17 ? allData[i].caption17 : "",
          photo18: allData[i].photo18 ? allData[i].photo18 : "",
          caption18: allData[i].caption18 ? allData[i].caption18 : "",
          photo19: allData[i].photo19 ? allData[i].photo19 : "",
          caption19: allData[i].caption19 ? allData[i].caption19 : "",
          photo20: allData[i].photo20 ? allData[i].photo20 : "",
          caption20: allData[i].caption20 ? allData[i].caption20 : "",
          submissionDate: allData[i].submissionDate
            ? allData[i].submissionDate
            : "",
        });
      }
      resolve(reportData);
    } catch {
      reject({
        error: "error message",
      });
    }
  });
}

/**
 * Retrieves a single work request data from the database.
 *
 * @param {string} requestId - The ID of the work request to retrieve.
 * @return {object} The retrieved work request data, or an error object if the retrieval fails.
 */
async function getOneWorkRequestData(requestId) {
  return new Promise(async (resolve, reject) => {
    try {
      // Retrieve the work request data from Firestore
      const querySnapshot = await db
        .collection("workRequest")
        .doc(requestId)
        .get();

      // Extract the data from the query snapshot
      const data = querySnapshot.data();
      const dataId = querySnapshot.id;

      // Assign the document ID to the "internalPoNumber" property
      data.internalPoNumber = dataId;

      // Map the data to a new object with specific properties
      const mappedRequestData = {
        internalPoNumber: data.internalPoNumber || "",
        customerPoNumber: data.customerPoNumber || "",
        clientName: data.clientName || "",
        contactNumber: data.contactNumber || "",
        invoiceEmailAddress: data.email || "",
        jobStatus: data.jobStatus || "",
        locationName: data.locationName || "",
        billingAddress: data.billingAddress || "",
        workAddress: data.workAddress || "",
        requestedBy: data.requestedBy || "",
        reportEmailAddress: data.reportEmail || "",
        workOrderType: data.workOrderType || "",
        calledInDate: data.calledInDate || "",
        activeCrew: data.activeCrew || "",
        additionalInformation: data.additionalInformation || "",
        workDescription: data.workDescription || "",
        PDFS: data.PDFS || "",
      };

      resolve(mappedRequestData);
    } catch (e) {
      reject({
        errorMessage: e,
        error: "error message",
      });
    }
  });
}

export {
  db,
  getWorkRequestData,
  getCrewData,
  getInvoiceData,
  getReportData,
  getOneWorkRequestData,
};
